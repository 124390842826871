import { EventEmitter } from 'events';
import { V2, V2O } from 'engine/vectors/v2';
import { Pointer } from './pointer';

type UserGestureEventListenerMap = {
  readonly SWIPE: (pointerPosition: V2, dragDistance: V2) => void;
  readonly DRAG: (pointerPosition: V2, dragDistance: V2) => void;
  readonly DRAG_START: (pointerPosition: V2) => void;
  readonly DRAG_END: (pointerPosition: V2, dragDistance: V2) => void;
};

export class Gesture extends EventEmitter {
  private swipeDistance: number;

  constructor(
    private readonly proportionalSwipeDistance: number,
    private readonly swipeTime: number,
    private readonly pointer: Pointer,
  ) {
    super();
    this.swipeDistance = this.proportionalSwipeDistance * window.innerWidth;
  }

  install(): void {
    window.addEventListener('resize', this.onResize);
    this.pointer.addListener('POINTER_UP', this.onPointerUp);
    this.pointer.addListener('POINTER_MOVE', this.onPointerMove);
    this.pointer.addListener('POINTER_DOWN', this.onPointerDown);

    this.pointer.install();
  }

  addListener<K extends keyof UserGestureEventListenerMap>(
    event: K,
    listener: UserGestureEventListenerMap[K],
  ): this {
    return super.addListener(event, listener);
  }

  emit<K extends keyof UserGestureEventListenerMap>(
    event: K,
    ...args: Parameters<UserGestureEventListenerMap[K]>
  ): boolean {
    return super.emit(event, ...args);
  }

  private onResize = (event: UIEvent) => {
    this.swipeDistance = this.proportionalSwipeDistance * window.innerWidth;
  };

  private onPointerUp = (pointerPosition: V2, dragDistance: V2, dragDuration: number) => {
    if (this.isSwipe(dragDistance, dragDuration)) {
      this.emit('SWIPE', pointerPosition, dragDistance);
    } else {
      this.emit('DRAG_END', pointerPosition, dragDistance);
    }
  };

  private onPointerMove = (pointerPosition: V2, dragDistance: V2) => {
    if (this.pointer.isPointerDown) {
      this.emit('DRAG', pointerPosition, dragDistance);
    }
  };

  private onPointerDown = (pointerPosition: V2) => {
    this.emit('DRAG_START', pointerPosition);
  };

  private isSwipe(dragDistance: V2, dragDuration: number) {
    return V2O.euclideanLength(dragDistance) > this.swipeDistance && dragDuration < this.swipeTime;
  }

  uninstall(): void {
    this.pointer.uninstall();
    this.removeAllListeners();
  }
}
