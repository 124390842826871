import { V3, V3O } from '../../vectors/v3';
import { ForceField } from './force-field';

export const createDampingForceField = (dampingConstant: V3): ForceField => {
  function dampingForceField(_: V3, velocity: V3) {
    const vSquared = V3O.multiply(velocity, velocity);
    const direction = V3O.sign(velocity);
    const vSquaredDirected = V3O.multiply(vSquared, direction);
    const force = V3O.scale(V3O.multiply(vSquaredDirected, dampingConstant), -1);
    return force;
  }

  return dampingForceField;
};
