import { useStore } from 'components/store/store';
import { ENVIRONMENT_CHANGE_DURATION } from 'config';
import React, { useEffect, useRef } from 'react';
import { useFrame } from 'react-three-fiber';
import { AmbientLight, Color, DirectionalLight } from 'three';
import { environments } from '../../images/environments';

const Light = (): React.ReactElement => {
  const environment = useStore((state) => state.environment);
  const lights = useStore((state) => state.lights);
  const ambientRef = useRef<AmbientLight>();
  const directionalRef = useRef<DirectionalLight>();
  const currentEnvironment = useRef<typeof environments[number]>(environment);
  let changeTime = 0;

  /**
   * On first frame we need to set color of lights immediately
   */
  useEffect(() => {
    if (!ambientRef.current || !directionalRef.current) return;
    ambientRef.current.color.copy(currentEnvironment.current.ambient);
    directionalRef.current.color.copy(currentEnvironment.current.directional);
  }, []);

  /**
   * Lerp between next color and starting color of this environment
   */
  useFrame((_, delta) => {
    const ambient = ambientRef.current;
    const directional = directionalRef.current;
    if (!ambient || !directional) return;

    if (changeTime === 0) {
      currentEnvironment.current.ambient.copy(ambient.color);
      currentEnvironment.current.directional.copy(directional.color);
    }

    changeTime += delta * 1000;
    const progression = Math.min(changeTime / ENVIRONMENT_CHANGE_DURATION, 1);
    lerpTo(ambient, currentEnvironment.current.ambient, environment.ambient, progression);
    lerpTo(
      directional,
      currentEnvironment.current.directional,
      environment.directional,
      progression,
    );
    lights.ambient.copy(ambient.color);
    lights.directional.copy(directional.color);
  });

  return (
    <>
      <ambientLight ref={ambientRef} intensity={0.73}></ambientLight>
      <directionalLight ref={directionalRef} position={[0, -0.3, 0.01]} intensity={1.15} />
    </>
  );
};

const lerpTo = (
  light: AmbientLight | DirectionalLight,
  colorFrom: Color,
  colorTo: Color,
  alpha: number,
) => {
  light.color = colorFrom.clone().lerp(colorTo, alpha);
};

export { Light };
