export class WordWrap {
  constructor(private wrapAt: number, private measureText: (text: string) => number) {}

  /**
   * Wrap lines until distance `wrapAt`. If a word is longer than `wrapAt` measured by measureText
   * it will overflow.
   */
  wrap(text: string): string[] {
    const chunks = this.breakWordsIntoChunks(text);
    const lines = this.assembleChunksToLines(chunks);
    return lines;
  }

  private breakWordsIntoChunks(text: string) {
    const onWordBoundaries = /\b/;

    return text
      .toString()
      .split(onWordBoundaries)
      .reduce((chunks: string[], word: string) => {
        const wordFragments = this.getWordFragments(word);
        return chunks.concat(wordFragments);
      }, []);
  }

  private getWordFragments(word: string) {
    const wordFragments = [];
    let remainingText = word;
    let endLetter = remainingText.length;
    while (endLetter > 0) {
      let i = 0;

      while (this.measureText(remainingText.slice(0, endLetter)) > this.wrapAt) {
        i++;
        endLetter = remainingText.length - i;
      }
      const cutWord = remainingText.slice(0, endLetter);
      wordFragments.push(cutWord);

      remainingText = remainingText.slice(endLetter, remainingText.length);
      endLetter = remainingText.length;
    }

    return wordFragments;
  }

  private assembleChunksToLines(chunks: string[]) {
    return chunks.reduce(
      (lines, rawChunk) => {
        const chunkIsEmpty = rawChunk === '';
        if (chunkIsEmpty) return lines;

        const chunk = this.replaceTabsWithSpaces(rawChunk);

        const lastLineIndex = lines.length - 1;
        const lastLineWouldBeTooLong =
          this.measureText(lines[lastLineIndex]) + this.measureText(chunk) > this.wrapAt;
        const chunkHasNewlines = chunk.match(/\n/);

        if (lastLineWouldBeTooLong) {
          lines[lastLineIndex] = this.removeEndingSpaces(lines, lastLineIndex);
          const chunkLines = this.getChunkLines(chunk);
          this.addChunksRespectingNewlines(lines, chunkLines);
          return lines;
        }

        if (chunkHasNewlines) {
          const chunkLines = this.getChunkLines(chunk);
          this.addFirstChunkToEndOfLine(lines, lastLineIndex, chunkLines);
          this.addChunksRespectingNewlines(lines, chunkLines);
          return lines;
        }

        lines[lastLineIndex] += chunk;
        return lines;
      },
      [''],
    );
  }

  private replaceTabsWithSpaces = (rawChunk: string) => rawChunk.replace(/\t/g, '    ');
  private removeEndingSpaces = (lines: string[], i: number) => lines[i].replace(/\s+$/, '');
  private getChunkLines = (chunk: string) => chunk.split(/\n/);

  private addFirstChunkToEndOfLine = (
    lines: string[],
    lastLineIndex: number,
    chunkLines: string[],
  ) => {
    lines[lastLineIndex] += chunkLines.shift();
  };

  private addChunksRespectingNewlines = (lines: string[], chunkLines: string[]) => {
    chunkLines.forEach((chunkLine) => {
      const chunkLineWithoutLeadingSpaces = chunkLine.replace(/^\s+/, '');
      lines.push(chunkLineWithoutLeadingSpaces);
    });
  };
}
