import axios from 'axios';
import {
  FADE_TIME,
  MAX_SERVER_PAPER_COUNT,
  PAPER_GROUPS,
  TEMPLATE_PAPER_COUNT,
  SERVER_IP,
} from 'config';
import { Entity } from 'engine/entity';
import { PreBuiltTextures } from 'textures';
import { Reducer } from 'react';
import { ReplaceableMaterial } from '../../drivers/replaceable-material';
import { Actions } from './actions';
import { AsyncDispatch } from './create-store';
import { DerivativeState, State } from './state';

export const asyncDispatch: AsyncDispatch<State, Actions> = (getState, action, dispatch) => {
  const SECONDS_TO_MILLIS = 1000;
  switch (action.type) {
    case 'ACCEPT_WRITING':
      window.setTimeout(
        () => dispatch({ type: 'TRANSITION_TO_ACCEPTED' }),
        FADE_TIME * SECONDS_TO_MILLIS,
      );
      break;

    default:
      break;
  }
};

export const reduce: Reducer<State, Actions> = (state, action) => {
  switch (action.type) {
    case 'START_PLAYING':
      state = {
        ...state,
        screen: 'PLAY',
      };

      break;

    case 'GET_WRITING_PAGE':
      const maybeChosenPaper = state.chosenPaper?.getComponent('SERVER_PAPER');
      maybeChosenPaper?.enterState('IDLE');

      let chosenPaper =
        state.templatePapers[Math.floor(Math.random() * state.templatePapers.length)];

      if (chosenPaper === undefined) {
        console.warn(`User has gone over limit of ${TEMPLATE_PAPER_COUNT} template papers`);
        chosenPaper = state.filledPapers[Math.floor(Math.random() * state.filledPapers.length)];
      }

      state = {
        ...state,
        screen: 'PLAY_TO_WRITING',
        chosenPaper: chosenPaper,
      };

      state = playWriteIn(state);

      chosenPaper.getComponent('WRITTEN_PAPER').enterState('WRITING');
      break;

    case 'START_WRITING':
      state = {
        ...state,
        screen: 'WRITING',
      };

      break;

    case 'FOCUS_WRITING':
      state = {
        ...state,
        screen: 'WRITING_FOCUSED',
      };

      break;

    case 'UNFOCUS_WRITING':
      state = {
        ...state,
        screen: 'WRITING',
      };

      break;

    case 'CANCEL_WRITING':
      {
        state.chosenPaper?.getComponent('WRITTEN_PAPER').enterState('OFFSCREEN');
        const { chosenPaperIndex, chosenPaper } = getNextServerPaper(state);

        state = {
          ...state,
          screen: 'PLAY',
          userText: '',
          chosenPaper,
          serverPaperIndex: chosenPaperIndex,
        };
      }
      break;

    case 'ACCEPT_WRITING':
      state = {
        ...state,
        screen: 'WRITING_TO_READY_TO_SUBMIT',
        userText: action.payload || state.userText,
      };

      state.chosenPaper?.getComponent('WRITTEN_PAPER').enterState('SUBMIT');
      break;

    case 'TRANSITION_TO_ACCEPTED': {
      const { chosenPaper } = state;
      if (state.screen !== 'WRITING_TO_READY_TO_SUBMIT' || !chosenPaper) break;
      state = {
        ...state,
        screen: 'READY_TO_SUBMIT',
      };

      chosenPaper.getComponent('WRITTEN_PAPER').enterState('CHOSEN');
      break;
    }

    case 'SUBMIT_WRITING': {
      sendUserText(state.userText);
      state = { ...state, userText: '', screen: 'PLAY' };
      state = playChooseOut(state);
      break;
    }

    case 'RELEASE_PAPER':
      if (state.isPlayEnabled !== true) break;

      if (state.screen === 'READY_TO_SUBMIT') state = reduce(state, { type: 'SUBMIT_WRITING' });
      state = releasePaper(state);
      state = playChooseOut(state);
      break;

    case 'DRAG_PAPER':
      if (state.isPlayEnabled !== true) break;
      const maybePaperComponent = state.chosenPaper?.getComponent('SERVER_PAPER');
      maybePaperComponent?.enterState('DRAGGED');
      break;

    case 'CHOOSE_PAPER':
      {
        if (state.isPlayEnabled !== true) break;
        const maybePaperComponent =
          state.chosenPaper?.tryGetComponent('WRITTEN_PAPER') ||
          state.chosenPaper?.getComponent('SERVER_PAPER');
        maybePaperComponent?.enterState('CHOSEN');
        state = playChooseIn(state);
      }
      break;

    case 'SET_PAPER_DIMENSIONS':
      state = {
        ...state,
        paperDimensions: action.payload,
      };

      break;

    case 'SET_USER_TEXT':
      state = {
        ...state,
        userText: action.payload,
      };
      break;

    case 'SET_SERVER_TEXTS':
      {
        const userSubmissionCount = Math.min(action.payload.length, MAX_SERVER_PAPER_COUNT);
        const serverTexts = action.payload;
        const serverPapers = [...new Array(userSubmissionCount)].map(() => new Entity());
        const serverMaterials = action.payload.slice(-PAPER_GROUPS).map(() => {
          const material = new ReplaceableMaterial(PreBuiltTextures.getServerTemplate());
          return material;
        });
        const serverPaperIndex = Math.floor(Math.random() * serverPapers.length);
        const chosenPaper = serverPapers[serverPaperIndex];

        if (serverTexts.length > 0) {
          state = {
            ...state,
            serverTexts,
            serverPapers,
            serverMaterials,
            chosenPaper,
            serverPaperIndex,
          };
        }

        updateMaterial(serverPaperIndex, state);
      }

      break;
    case 'CHANGE_ENVIRONMENT': {
      state = {
        ...state,
        environment: action.payload,
      };
    }
  }

  //console.log(action);

  const derivativeState = reduceDerivativeState(state.screen);
  state = { ...state, ...derivativeState };

  return state;
};
export const reduceDerivativeState = (screen: State['screen']): DerivativeState => {
  let result: DerivativeState = {
    isIntroVisible: false,
    isPlayVisible: false,
    isCanvasWritingVisible: false,
    isDOMWritingVisible: false,
    isWritingVisible: false,
    isWritingEnabled: false,
    isPlayEnabled: false,
    isSubmitButtonVisible: false,
    isGetWritingPaperVisible: false,
    isColophonButtonVisible: false,
    isShareButtonVisible: false,
    isCloseButtonVisible: false,
  };
  switch (screen) {
    case 'INTRO':
      result = {
        ...result,
        isIntroVisible: true,
        isPlayEnabled: true,
      };
      break;

    case 'PLAY':
      result = {
        ...result,
        isGetWritingPaperVisible: true,
        isColophonButtonVisible: true,
        isShareButtonVisible: true,
        isPlayVisible: true,
        isPlayEnabled: true,
      };
      break;

    case 'PLAY_TO_WRITING':
      result = {
        ...result,
        isPlayVisible: true,
        isWritingVisible: true,
        isColophonButtonVisible: true,
        isShareButtonVisible: true,
      };
      break;

    case 'WRITING':
      result = {
        ...result,
        isPlayVisible: true,
        isDOMWritingVisible: true,
        isWritingVisible: true,
        isWritingEnabled: true,
        isColophonButtonVisible: true,
        isShareButtonVisible: true,
        isCloseButtonVisible: true,
      };
      break;

    case 'WRITING_FOCUSED':
      result = {
        ...result,
        isPlayVisible: true,
        isDOMWritingVisible: true,
        isWritingVisible: true,
        isWritingEnabled: true,
        isCloseButtonVisible: true,
      };
      break;

    case 'WRITING_TO_READY_TO_SUBMIT':
      result = {
        ...result,
        isPlayVisible: true,
        isCanvasWritingVisible: true,
        isDOMWritingVisible: false,
        isWritingVisible: true,
        isCloseButtonVisible: true,
      };
      break;

    case 'READY_TO_SUBMIT':
      result = {
        ...result,
        isPlayVisible: true,
        isSubmitButtonVisible: true,
        isPlayEnabled: true,
        isCloseButtonVisible: true,
      };
      break;
  }

  return result;
};

function playChooseIn(state: State) {
  state = { ...state, chooseInCount: state.chooseInCount + 1 };
  return state;
}

function playChooseOut(state: State) {
  state = { ...state, chooseOutCount: state.chooseOutCount + 1 };
  return state;
}

function playWriteIn(state: State) {
  state = { ...state, writeBeginCount: state.writeBeginCount + 1 };
  return state;
}

function getNextServerPaper(state: State) {
  if (state.serverPaperIndex === undefined)
    return { chosenPaperIndex: undefined, chosenPaper: undefined };
  const chosenPaperIndex = (state.serverPaperIndex + 1) % state.serverPapers.length;
  const chosenPaper = state.serverPapers[chosenPaperIndex];
  return { chosenPaperIndex, chosenPaper };
}

function sendUserText(userText: string) {
  const params = new URLSearchParams();
  params.append('data', userText);
  axios.post(SERVER_IP + '/save-new.php', params);
}

function updateMaterial(chosenPaperIndex: number, state: State) {
  const materialIndex = chosenPaperIndex % state.serverMaterials.length;
  const material = state.serverMaterials[materialIndex];
  const text = state.serverTexts[chosenPaperIndex];
  material.replaceWith(text);
}

function releasePaper(state: State) {
  const maybePaperComponent =
    state.chosenPaper?.tryGetComponent('WRITTEN_PAPER') ||
    state.chosenPaper?.getComponent('SERVER_PAPER');

  maybePaperComponent?.enterState('IDLE');
  const { chosenPaperIndex, chosenPaper } = getNextServerPaper(state);
  if (chosenPaperIndex === undefined) return state;
  updateMaterial(chosenPaperIndex, state);

  const result = {
    ...state,
    chosenPaper,
    serverPaperIndex: chosenPaperIndex,
  };

  return result;
}
