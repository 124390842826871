import React, { useEffect, useMemo } from 'react';
import { Gesture } from '../../drivers/gesture';
import { Pointer } from '../../drivers/pointer';
import { useStore } from '../store/store';
import { SWIPE_DISTANCE, SWIPE_TIME } from 'config';
import shallow from 'zustand/shallow';
import { Dispatch } from 'components/store/actions';
import { State } from 'components/store/state';
import { V2, V2O } from 'engine/vectors/v2';
import { useThree } from 'react-three-fiber';
import { WrittenPaperComponent } from 'engine/paper/written-paper-component';
import { ServerPaperComponent } from 'engine/paper/server-paper-component';

const swipeDistance = SWIPE_DISTANCE;
const swipeTime = SWIPE_TIME;
const selector = (state: State & { dispatch: Dispatch }) =>
  [
    state.chosenPaper?.tryGetComponent('WRITTEN_PAPER') ||
      state.chosenPaper?.tryGetComponent('SERVER_PAPER'),
    state.isPlayEnabled,
    state.dispatch,
  ] as const;

export const Interactions = (): React.ReactElement | null => {
  const [maybePaper, isPlayEnabled, dispatch] = useStore(selector, shallow);
  return isPlayEnabled && !!maybePaper ? (
    <EnableGestures paper={maybePaper} dispatch={dispatch} />
  ) : null;
};

const EnableGestures = ({
  paper,
  dispatch,
}: {
  paper: ServerPaperComponent | WrittenPaperComponent;
  dispatch: Dispatch;
}) => {
  const { gl } = useThree();
  const gesture = useMemo(() => new Gesture(swipeDistance, swipeTime, new Pointer(gl.domElement)), [
    gl.domElement,
  ]);
  useEffect(() => {
    gesture.install();

    gesture.addListener('DRAG_START', () => {
      onDragStart(paper, dispatch);
    });

    gesture.addListener('DRAG_END', (_, dragDistance) => {
      onDragEnd(dragDistance, paper, dispatch);
    });

    gesture.addListener('DRAG', (_, dragDistance) => {
      onDrag(dragDistance, paper, dispatch);
    });

    gesture.addListener('SWIPE', () => {
      onSwipe(paper, dispatch);
    });

    return () => {
      gesture.uninstall();
    };
  }, [dispatch, gesture, paper]);
  return null;
};

function onDragStart(
  paper: WrittenPaperComponent | ServerPaperComponent,
  dispatch: Dispatch,
): void {
  if (paper.isChosen()) return;
  if (paper.state !== 'IDLE') dispatch({ type: 'RELEASE_PAPER' });
  dispatch({ type: 'DRAG_PAPER' });
}

function onDragEnd(
  drag: V2,
  paper: WrittenPaperComponent | ServerPaperComponent,
  dispatch: Dispatch,
): void {
  const DRAG_THRESHOLD = 10;
  const PROGRESS_THRESHOLD = 0.9;

  const isClick = V2O.euclideanLength(drag) < DRAG_THRESHOLD;
  const isCloseToScreen = paper.progress > PROGRESS_THRESHOLD;

  if (paper.isChosen() && isClick) {
    dispatch({ type: 'RELEASE_PAPER' });
    return;
  }

  if (isCloseToScreen || isClick) {
    dispatch({ type: 'CHOOSE_PAPER' });
  } else {
    dispatch({ type: 'RELEASE_PAPER' });
  }
}

function onDrag(
  drag: V2,
  paper: WrittenPaperComponent | ServerPaperComponent,
  dispatch: Dispatch,
): void {
  paper.setDragDistance(drag);
  if (paper.isChosen()) return;

  dispatch({ type: 'DRAG_PAPER' });
}

function onSwipe(paper: WrittenPaperComponent | ServerPaperComponent, dispatch: Dispatch): void {
  if (paper.isChosen()) {
    dispatch({ type: 'RELEASE_PAPER' });
  } else {
    dispatch({ type: 'CHOOSE_PAPER' });
  }
}
