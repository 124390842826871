export class MeasureText {
  characterMap: Map<string, number> = new Map();
  context: CanvasRenderingContext2D | undefined;
  lastA = 0;

  measure(text: string, context: CanvasRenderingContext2D): number {
    if (context !== this.context || this.context.font !== context.font) {
      this.characterMap = new Map();
      this.context = context;
    }
    const thisA = context.measureText('a').width;
    if (this.lastA !== thisA) {
      this.characterMap = new Map();
      this.lastA = thisA;
    }
    const width = [...text].reduce((acc, character) => {
      const maybeWidth = this.characterMap.get(character);
      if (maybeWidth === undefined) {
        const width = context.measureText(character).width;
        this.characterMap.set(character, width);
        return acc + width;
      }

      return acc + maybeWidth;
    }, 0);

    return width;
  }
}
