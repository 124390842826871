import { Mesh, BufferGeometry, MeshPhongMaterial } from 'three';
import { Component } from '../component';
import { Entity } from '../entity';

export class MeshComponent extends Component {
  mesh: Mesh<BufferGeometry, MeshPhongMaterial>;

  constructor(parent: Entity, mesh: Mesh<BufferGeometry, MeshPhongMaterial>) {
    super({ type: 'MESH', parent, dependencies: [] });
    this.mesh = mesh;
  }
}
