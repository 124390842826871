import React from 'react';
import { useControl } from 'react-three-gui';

export const LightGUI = (): React.ReactElement => {
  const ambientColor = useControl('Ambient Color', { type: 'color', value: '#ffc179' }) as string;
  const directionalColor = useControl('Directional Color', {
    type: 'color',
    value: '#ffc179',
  }) as string;
  const ambientIntensity = useControl('Ambient Intensity', { type: 'number', value: 1 }) as number;
  const directionalIntensity = useControl('Directional Intensity', {
    type: 'number',
    value: 1,
  }) as number;
  const locationX = useControl('Location X', {
    type: 'number',
    value: 1,
    min: -1,
    max: 1,
  }) as number;
  const locationY = useControl('Location Y', {
    type: 'number',
    value: 1,
    min: -1,
    max: 1,
  }) as number;
  const locationZ = useControl('Location Z', {
    type: 'number',
    value: 1,
    min: -1,
    max: 1,
  }) as number;

  return (
    <>
      <ambientLight intensity={ambientIntensity} color={ambientColor}></ambientLight>
      <directionalLight
        position={[locationX, locationY, locationZ]}
        color={directionalColor}
        intensity={directionalIntensity}
      />
    </>
  );
};
