import React from 'react';
// import { Canvas } from './components/fiber/canvas';
import { Interactions } from './components/fiber/interactions';
import { PaperMeasurer } from './components/fiber/paper-measurer';
import { CloseButton } from './components/react/close-button';
import { Intro } from './components/react/intro';
import { TextInput } from './components/react/text-input';
import './styles/app.css';
import { TextureUpdater } from 'components/fiber/texture-updater';
import { Engine } from 'components/fiber/engine';
import { ServerPapers } from 'components/fiber/server-papers';
import { WrittenPapers } from 'components/fiber/written-papers';
import { Canvas } from 'react-three-fiber';
import { useStore } from 'components/store/store';
import { FADE_TIME } from 'config';
import { State } from 'zustand';
import { Background } from 'components/fiber/background';
import { UI } from 'components/react/ui';
import { Controls, ControlsProvider } from 'react-three-gui';
import { LightGUI } from 'components/fiber/light-gui';
import { Light } from 'components/fiber/light';
import { Sound } from 'components/react/sound';
import { Logos } from 'components/logos/logos';
import { BackgroundGUI } from 'components/fiber/background-gui';

const selector = (state: State) => state.isPlayVisible;

export const App = (): React.ReactElement => {
  const isPlayVisible = useStore(selector);
  const isGUI = document.location.search.substring(1) === 'gui';

  const injectedStyle: React.CSSProperties = {
    opacity: isPlayVisible ? 1 : 0,
    transition: `${FADE_TIME}s`,
    touchAction: isPlayVisible ? 'auto' : 'none',
    pointerEvents: isPlayVisible ? 'auto' : 'none',
  };
  return (
    <>
      <Logos />
      <ControlsProvider>
        <div className="App">
          <Sound />
          {isGUI ? <BackgroundGUI /> : <Background />}
          <Canvas
            style={{
              ...injectedStyle,
              height: '100%',
              width: '100%',
              position: 'absolute',
              overflow: 'hidden',
            }}
            orthographic={false}
            pixelRatio={window.devicePixelRatio}
            webgl1={true}
            camera={{ near: 0.1, far: 10000 }}
            noEvents={true}
            onPointerMove={undefined}
          >
            <PaperMeasurer />
            <TextureUpdater />
            {isGUI ? <LightGUI /> : <Light />}
            <Interactions />
            <Engine />
            <ServerPapers />
            <WrittenPapers />
          </Canvas>
          <Intro />
          <TextInput />
          <CloseButton />
          <UI />
        </div>

        {isGUI && <Controls />}
      </ControlsProvider>
    </>
  );
};
