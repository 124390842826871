import { Component } from '../component';
import { Entity } from '../entity';
import { Transform } from './transform';

export class TransformComponent extends Component {
  transform: Transform;

  constructor(parent: Entity, transform = Transform.zero()) {
    super({ type: 'TRANSFORM', parent, dependencies: [] });
    this.transform = transform;
  }
}
