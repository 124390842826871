import { Component } from '../component';
import { Entity } from '../entity';
import { EasingFunctions } from './easing';
import { easing } from './easing';
import { IAnimationComponent } from './i-animation-component';

export interface AnimationComponentData {
  curve: EasingFunctions;
  progress?: number;
  getProgress: (entity: Entity, animation: IAnimationComponent, progress: number) => number;
  onProgress?: (entity: Entity, animation: IAnimationComponent, progress: number) => void;
  onComplete?: (entity: Entity, animation: IAnimationComponent, progress: number) => void;
  onStop?: (entity: Entity) => void;
}

export class AnimationComponent extends Component implements IAnimationComponent {
  curve: EasingFunctions;
  progress: number;
  private static readonly START_PROGRESS = 0;
  private static readonly END_PROGRESS = 1;

  getProgress: (entity: Entity, animation: IAnimationComponent, progress: number) => number;
  onProgress?: (entity: Entity, animation: IAnimationComponent, progress: number) => void;
  onComplete?: (entity: Entity, animation: IAnimationComponent, progress: number) => void;
  onStop?: (entity: Entity) => void;
  startTime: number;

  constructor(
    parent: Entity,
    {
      curve,
      progress = AnimationComponent.START_PROGRESS,
      onProgress,
      onComplete,
      onStop,
      getProgress,
    }: AnimationComponentData,
  ) {
    super({ type: 'ANIMATION', parent, dependencies: ['TRANSFORM'] });
    this.curve = curve;
    this.progress = progress;

    this.getProgress = getProgress;
    this.onProgress = onProgress;
    this.onComplete = onComplete;
    this.onStop = onStop;

    this.startTime = Date.now();
  }
  update(): void {
    this.validateDependencies();

    const progress = this.getProgress(this.parent, this, this.progress);
    const easedProgress = easing[this.curve](progress);
    this.onProgress?.(this.parent, this, easedProgress);

    if (progress >= AnimationComponent.END_PROGRESS)
      this.onComplete?.(this.parent, this, easedProgress);
  }

  stop(): void {
    this.parent.removeComponent('ANIMATION');
    this.onStop?.(this.parent);
  }
}
