import { PAPER_COLOR, TEXT_SETTINGS } from 'config';
import { CanvasTexture, MeshPhongMaterial } from 'three';
import { canvasWriter } from './canvas-writer';
export class ReplaceableMaterial extends MeshPhongMaterial {
  constructor(startTexture: CanvasTexture) {
    super({ map: startTexture, transparent: true, color: PAPER_COLOR, toneMapped: false });
  }

  replaceWith(text: string): void {
    canvasWriter.clearCanvas();
    canvasWriter.writeText('Sinds jij\ndood bent...');
    canvasWriter.writeTextAt(text, TEXT_SETTINGS.userTextLineOffset);
    const texture = canvasWriter.getTexture();
    this.map = texture;
    this.needsUpdate = true;
  }
}
