import { environments } from './../../images/environments';
import { ReplaceableMaterial } from '../../drivers/replaceable-material';
import { createAsyncStore } from './create-store';
import { Actions } from './actions';
import { asyncDispatch, reduce, reduceDerivativeState } from './reducers';
import { State } from './state';
import { TEMPLATE_PAPER_COUNT } from 'config';
import { Entity } from 'engine/entity';

const serverTexts: string[] = [];
const serverPapers: Entity[] = [];
const serverMaterials: ReplaceableMaterial[] = [];
const writtenPapers = [...new Array(TEMPLATE_PAPER_COUNT)].map(() => new Entity());
const templatePapers = [...writtenPapers];
const filledPapers: Entity[] = [];
const serverPaperIndex = undefined;
const chosenPaper = undefined;

const initialState: State = {
  screen: 'INTRO',
  userText: '',
  serverTexts,
  paperDimensions: { top: 0, left: 0, right: 0, bottom: 0, width: 0, height: 0 },
  derivativeState: reduceDerivativeState('INTRO'),
  writtenPapers,
  templatePapers,
  serverPapers,
  serverMaterials,
  filledPapers,
  serverPaperIndex,
  chosenPaper,
  environment: environments[0],
  lights: {
    ambient: environments[0].ambient.clone(),
    directional: environments[0].directional.clone(),
  },
  ...reduceDerivativeState('INTRO'),
  writeBeginCount: 0,
  chooseOutCount: 0,
  chooseInCount: 0,
};

export const useStore = createAsyncStore<State, Actions>(asyncDispatch, reduce, initialState);
