import { ComponentTypeMap, Entity } from './entity';

export type ComponentTypes = keyof ComponentTypeMap;

interface ComponentData {
  type: ComponentTypes;
  parent: Entity;
  dependencies: ComponentTypes[];
}
export class Component implements ComponentData {
  type: ComponentTypes;
  parent: Entity;
  dependencies: ComponentTypes[];

  constructor({ type, parent, dependencies }: ComponentData) {
    this.type = type;
    this.parent = parent;
    this.dependencies = dependencies;
  }

  validateDependencies(): void {
    if (!this.hasDependencies()) this.throwMissingDependenciesError();
  }

  hasDependencies(): boolean {
    return this.getMissingDependencies().length === 0;
  }

  getMissingDependencies(): ComponentTypes[] {
    return this.dependencies.filter((dependency) => !this.parent.components.has(dependency));
  }

  throwMissingDependenciesError(): void {
    throw new Error(
      `${this.constructor.name} has missing dependencies ${this.getMissingDependencies()}`,
    );
  }

  throwMissingComponentError(component: ComponentTypes): void {
    throw new Error(`Parent of ${this.constructor.name} does not have component ${component}`);
  }
}
