import { EventEmitter } from 'events';
import { V2, V2O } from 'engine/vectors/v2';

type PointerEventListenerMap = {
  readonly POINTER_DOWN: (pointerPosition: V2) => void;
  readonly POINTER_UP: (pointerPosition: V2, dragDistance: V2, dragDuration: number) => void;
  readonly POINTER_MOVE: (pointerPosition: V2, dragDistance: V2) => void;
};

export class Pointer extends EventEmitter {
  isPointerDown = false;
  pointerPosition: V2 = V2O.zero();
  dragStart: V2 = V2O.zero();
  dragDistance: V2 = V2O.zero();
  dragDuration = 0;
  dragStartTime = Date.now();
  domElement: HTMLElement;
  animationFrameId: number | undefined;

  constructor(domElement: HTMLElement) {
    super();
    this.domElement = domElement;
  }

  addListener<K extends keyof PointerEventListenerMap>(
    event: K,
    listener: PointerEventListenerMap[K],
  ): this {
    return super.addListener(event, listener);
  }

  emit<K extends keyof PointerEventListenerMap>(
    event: K,
    ...args: Parameters<PointerEventListenerMap[K]>
  ): boolean {
    return super.emit(event, ...args);
  }

  private pointerDown = (event: TouchEvent | MouseEvent) => {
    event.preventDefault();
    this.pointerPosition = Pointer.getPointerPosition(event);
    this.dragStart = this.pointerPosition;
    this.dragStartTime = Date.now();
    this.isPointerDown = true;

    this.emit('POINTER_DOWN', this.pointerPosition);
  };

  private pointerUp = (event: TouchEvent | MouseEvent) => {
    if (!this.isPointerDown) return;
    event.preventDefault();
    this.isPointerDown = false;
    this.pointerPosition = Pointer.getPointerPosition(event);
    this.dragDistance = V2O.subtract(this.dragStart, this.pointerPosition);
    this.dragDuration = Date.now() - this.dragStartTime;

    this.emit('POINTER_UP', this.pointerPosition, this.dragDistance, this.dragDuration);
  };

  private pointerMove = (event: TouchEvent | MouseEvent) => {
    if (!this.isPointerDown) return;
    event.preventDefault();
    if (!!this.animationFrameId) return;

    this.animationFrameId = requestAnimationFrame(() => {
      this.pointerPosition = Pointer.getPointerPosition(event);
      this.dragDistance = V2O.subtract(this.dragStart, this.pointerPosition);

      this.emit('POINTER_MOVE', this.pointerPosition, this.dragDistance);
      this.animationFrameId = undefined;
    });
  };

  install(): void {
    this.domElement.addEventListener('mousedown', this.pointerDown, { passive: false });
    this.domElement.addEventListener('touchstart', this.pointerDown, { passive: false });
    document.body.addEventListener('mouseup', this.pointerUp, { passive: false });
    document.body.addEventListener('touchend', this.pointerUp, { passive: false });
    document.body.addEventListener('touchmove', this.pointerMove, { passive: false });
    document.body.addEventListener('mousemove', this.pointerMove, { passive: false });
  }

  uninstall(): void {
    this.domElement.removeEventListener('mousedown', this.pointerDown);
    this.domElement.removeEventListener('touchstart', this.pointerDown);
    document.body.removeEventListener('mouseup', this.pointerUp);
    document.body.removeEventListener('touchend', this.pointerUp);
    document.body.removeEventListener('touchmove', this.pointerMove);
    document.body.removeEventListener('mousemove', this.pointerMove);

    this.removeAllListeners();
  }

  static isTouch(event: MouseEvent | TouchEvent): event is TouchEvent {
    return !!(event as TouchEvent).touches;
  }

  static getPointerPosition(event: MouseEvent | TouchEvent): V2 {
    let clientX = 0;
    let clientY = 0;
    if (Pointer.isTouch(event)) {
      if (event.touches.length > 0) {
        ({ clientX, clientY } = event.touches[0]);
      } else {
        ({ clientX, clientY } = event.changedTouches[0]);
      }
    } else {
      ({ clientX, clientY } = event);
    }
    return [clientX, clientY] as V2;
  }
}
