import { Component } from '../component';
import { Entity } from '../entity';

export class BufferedDisplayComponent extends Component {
  constructor(parent: Entity) {
    super({ type: 'BUFFERED_DISPLAY', parent, dependencies: ['TRANSFORM', 'MESH'] });
  }

  update(): void {
    this.validateDependencies();
    const { position, rotation } = this.parent.getComponent('TRANSFORM').transform;
    const { mesh } = this.parent.getComponent('MESH');
    mesh.position.set(position[0], position[1], position[2]);
    mesh.rotation.set(rotation[0], rotation[1], rotation[2]);
  }
}
