import { V3, V3O } from '../../vectors/v3';
import { ForceField } from './force-field';

export const createSpringForceField = ({
  springCenter,
  springConstant,
}: {
  springCenter: V3;
  springConstant: V3;
}): ForceField => {
  function springForceField(position: V3) {
    const distance = V3O.subtract(position, springCenter);
    const force = V3O.scale(V3O.multiply(distance, springConstant), -1);
    return force;
  }

  return springForceField;
};
