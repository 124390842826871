import { PAPER_BUFFER_GEOMETRY } from '../../config';
import { PlaneBufferGeometry, BufferAttribute } from 'three';
import { Component } from '../component';
import { Entity } from '../entity';
import { ClothSettings, PAPER_ASPECT_RATIO } from 'config';
import { V3, V3O } from 'engine/vectors/v3';
import { TimedAnimationComponent } from 'engine/animations/timed-animation-component';

export class ClothComponent extends Component {
  pins: number[];
  particles: Particle[];
  constraints: [Particle, Particle, number][];
  geometry: PlaneBufferGeometry;
  shouldSimulate = true;
  shouldUpdate = true;
  constructor(parent: Entity, public timeStepScale = 1) {
    super({ type: 'CLOTH', parent, dependencies: ['MESH'] });

    const geometry = new PlaneBufferGeometry(
      1,
      1.414,
      ClothSettings.X_SEGMENTS,
      ClothSettings.Y_SEGMENTS,
    );
    // Top row
    const pins = [...new Array(geometry.parameters.widthSegments + 1)]
      .fill(0)
      .map((_, index) => index);

    const particles = createParticles(geometry);
    const constraints = addConstraints(geometry, particles);

    this.geometry = geometry;
    this.constraints = constraints;
    this.pins = pins;
    this.particles = particles;
  }

  private revertGeometry(): void {
    this.validateDependencies();
    const mesh = this.parent.getComponent('MESH').mesh;
    mesh.geometry = PAPER_BUFFER_GEOMETRY;
    this.shouldUpdate = false;
    this.shouldSimulate = false;
  }

  animateRemoveClothEffect(): void {
    this.validateDependencies();
    this.parent.removeComponent('ANIMATION');
    this.shouldSimulate = false;
    this.shouldUpdate = true;

    this.parent.setComponent(TimedAnimationComponent, this.parent, {
      curve: 'easeInOutCubic',
      durationMillis: 1000,
      progress: 0,
      onProgress: (entity, component, progress): void => {
        this.particles.forEach((particle) => {
          particle.position = V3O.lerp(particle.position, particle.original, progress);
          particle.previous = V3O.copy(particle.position);
        });
      },
      onComplete: (entity, animation) => {
        animation.stop();
        this.removeClothEffect();
      },
    });
  }

  removeClothEffect(): void {
    this.revertGeometry();
    this.parent.removeComponent('CLOTH');
  }

  update(): void {
    this.validateDependencies();
    if (this.shouldSimulate) {
      this.simulate();
    }
    if (this.shouldUpdate) {
      const { geometry, particles } = this;
      const vertices = geometry.getAttribute('position') as BufferAttribute;

      // Set positions of vertices
      particles.forEach(({ position }, index) => {
        vertices.setXYZ(index, ...position);
      });
      vertices.needsUpdate = true;
      geometry.computeVertexNormals();
    }
  }

  static wind(time: number): V3 {
    const speed = 1 / 7000;
    const xSpeed = 1 / 2000;
    const ySpeed = 1 / 3000;
    const zSpeed = 1 / 1000;

    const strength =
      Math.cos(time * speed) * ClothSettings.WIND_PERIODIC_AMPLITUDE +
      ClothSettings.WIND_BASE_STRENGTH;

    const force = V3O.scale(
      V3O.normalise([
        Math.sin(time * xSpeed) * 0.5,
        Math.cos(time * ySpeed),
        Math.sin(time * zSpeed),
      ]),
      strength,
    );

    return force;
  }

  private simulate() {
    const { geometry, particles, constraints, pins } = this;
    const vertices = geometry.getAttribute('position') as BufferAttribute;
    const mesh = this.parent.getComponent('MESH').mesh;
    mesh.geometry = this.geometry;

    const windForce = ClothComponent.wind(Date.now());

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const vertIndicesPerFace = geometry.index! as BufferAttribute;

    for (let faceIndex = 0; faceIndex < vertIndicesPerFace.count; faceIndex = faceIndex + 3) {
      const [vertIndexA, vertIndexB, vertIndexC] = getXYZ(vertIndicesPerFace, faceIndex);

      const vertexA = getXYZ(vertices, vertIndexA);
      const vertexB = getXYZ(vertices, vertIndexB);
      const vertexC = getXYZ(vertices, vertIndexC);

      const directionCtoB = V3O.subtract(vertexC, vertexB);
      const directionAtoB = V3O.subtract(vertexA, vertexB);
      const normal = V3O.normalise(V3O.crossProduct(directionCtoB, directionAtoB));

      const forceDirection = V3O.normalise(normal);
      const forceMagnitude = V3O.dotProduct(windForce, normal);
      const forceOnPlane = V3O.scale(forceDirection, forceMagnitude);

      particles[vertIndexA].addForce(forceOnPlane);
      particles[vertIndexB].addForce(forceOnPlane);
      particles[vertIndexC].addForce(forceOnPlane);
    }

    // // Add gravity to points
    particles.forEach((particle) => {
      particle.position = V3O.lerp(particle.position, particle.original, 0.016);
      particle.previous = V3O.lerp(particle.previous, particle.original, 0.016);

      particle.addForce(ClothSettings.GRAVITY_FORCE);
      particle.update(
        ClothSettings.TIME_STEP_SQUARED * this.timeStepScale,
        ClothSettings.MASS,
        ClothSettings.DRAG,
      );
    });

    // Start Constraints
    constraints.forEach((constraint) => ClothComponent.satisfyConstraints(...constraint));

    // Attach points to pins
    pins.forEach((xy) => {
      const particle = particles[xy];
      particle.position = V3O.copy(particle.original);
      particle.previous = V3O.copy(particle.original);
    });
  }

  // Moves p1 to p2 by distance
  static satisfyConstraints(p1: Particle, p2: Particle, distance: number): void {
    const diff = V3O.subtract(p2.position, p1.position);
    const currentDist = V3O.euclideanLength(diff);

    if (currentDist === 0) return; // prevents division by 0

    const correction = V3O.scale(diff, 1 - distance / currentDist);
    const correctionHalf = V3O.scale(correction, 0.5);

    p1.position = V3O.add(p1.position, correctionHalf);
    p2.position = V3O.subtract(p2.position, correctionHalf);
  }
}

function addConstraints(geometry: PlaneBufferGeometry, particles: Particle[]) {
  const { widthSegments, heightSegments } = geometry.parameters;
  const xSegDistance: number = 1 / widthSegments;
  const ySegDistance: number = xSegDistance * PAPER_ASPECT_RATIO;
  const constraints: [Particle, Particle, number][] = [];

  // Add constraints to inner cloth
  for (let yIndex = 0; yIndex < heightSegments; yIndex++) {
    for (let xIndex = 0; xIndex < widthSegments; xIndex++) {
      const centerParticle = particles[index2ToIndex1(xIndex, yIndex, widthSegments)];
      const belowParticle = particles[index2ToIndex1(xIndex, yIndex + 1, widthSegments)];
      const rightParticle = particles[index2ToIndex1(xIndex + 1, yIndex, widthSegments)];

      constraints.push([centerParticle, belowParticle, ySegDistance]);
      constraints.push([centerParticle, rightParticle, xSegDistance]);
    }
  }

  // Add constraints to edges
  for (let yIndex = 0; yIndex < heightSegments; yIndex++) {
    const centerParticle = particles[index2ToIndex1(widthSegments, yIndex, widthSegments)];
    const belowParticle = particles[index2ToIndex1(widthSegments, yIndex + 1, widthSegments)];

    constraints.push([centerParticle, belowParticle, ySegDistance]);
  }
  for (let xIndex = 0; xIndex < widthSegments; xIndex++) {
    const centerParticle = particles[index2ToIndex1(xIndex, heightSegments, widthSegments)];
    const rightParticle = particles[index2ToIndex1(xIndex + 1, heightSegments, widthSegments)];

    constraints.push([centerParticle, rightParticle, xSegDistance]);
  }

  return constraints;
}

const index2ToIndex1 = (u: number, v: number, widthSegments: number): number =>
  u + v * (widthSegments + 1);

class Particle {
  position: V3;
  previous: V3;
  original: V3;
  force: V3 = V3O.zero();
  constructor(initialPosition: V3, mass: number, drag: number) {
    this.position = V3O.copy(initialPosition);
    this.previous = V3O.copy(initialPosition);
    this.original = V3O.copy(initialPosition);
  }
  addForce(force: V3): void {
    this.force = V3O.add(force, this.force);
  }

  update(timeStepSquared: number, mass: number, drag: number): void {
    const acceleration = V3O.scale(this.force, 1 / mass);

    const velocity = V3O.subtract(this.position, this.previous);
    const velocityWithDrag = V3O.scale(velocity, drag);

    const positionWithVelocity = V3O.add(this.position, velocityWithDrag);

    const displacementFromAcceleration = V3O.scale(acceleration, timeStepSquared);
    const positionWithAcceleration = V3O.add(positionWithVelocity, displacementFromAcceleration);

    this.previous = this.position;
    this.position = positionWithAcceleration;

    this.force = V3O.zero();
  }
}
function createParticles(geometry: PlaneBufferGeometry) {
  const particles = [];

  const vertices = geometry.getAttribute('position') as BufferAttribute;
  for (let index = 0; index < vertices.count; index = index + 1) {
    const vertex = getXYZ(vertices, index);
    particles.push(new Particle(vertex, ClothSettings.MASS, ClothSettings.DRAG));
  }
  return particles;
}

function getXYZ(attribute: BufferAttribute, index: number) {
  return [attribute.getX(index), attribute.getY(index), attribute.getZ(index)] as V3;
}
