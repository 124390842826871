import { V3, V3O } from './engine/vectors/v3';
import { PlaneBufferGeometry, PlaneGeometry, PerspectiveCamera } from 'three';
import { ForceField } from 'engine/physics/fields/force-field';
import { createSpringForceField } from 'engine/physics/fields/spring-force-field';
import { createTorusForceField } from 'engine/physics/fields/torus-force-field';
import { createDampingForceField } from 'engine/physics/fields/damping-force-field';
import { createWindForceField } from 'engine/physics/fields/wind-force-field';

export const PAPER_COLOR = 'white';
export const PAPER_ASPECT_RATIO = 1.377;
export const PAPER_TEXTURE_SIZE = { width: 1024, height: PAPER_ASPECT_RATIO * 1024 };
export const PAPER_MASS = 1;
export const PAPERS_CENTER = [0, 8, -20] as V3;
export const STARTING_VELOCITY = 10;
export const FADE_TIME = 1; // Seconds
export const INTRO_HOLD_TIME = 4000; //ms
export const CANVAS_DELAY_TIME = 1000; //ms
export const ENVIRONMENT_CHANGE_INTERVAL = 25000;
export const ENVIRONMENT_CHANGE_DURATION = 5000;
export const OFFSCREEN_POSITION = [0, -10, 0] as const;

export const PAPER_GROUPS = 20;
export const PAPERS_PER_GROUP = 20;
export const MAX_SERVER_PAPER_COUNT = PAPER_GROUPS * PAPERS_PER_GROUP;
export const TEMPLATE_PAPER_COUNT = 10;

export const TEXT_SETTINGS = {
  fontSize: 0.1107,
  font: 'larsregular',
  lineHeight: 0.1297,
  paddingLeft: 0.063,
  paddingRight: 0.063,
  paddingTop: 0.19,
  paddingBottom: 0.063,
  userTextLineOffset: 2.58,
  maxCharacters: 90,
  minCharacters: 0,
  fontColor: 'black',
};

export const PAPER_BUFFER_GEOMETRY = new PlaneBufferGeometry(1, PAPER_ASPECT_RATIO);
export const PAPER_GEOMETRY = new PlaneGeometry(1, PAPER_ASPECT_RATIO);

export const SWIPE_DISTANCE = 0.2;
export const SWIPE_TIME = 500;

export const FORCES = createForceFields(PAPERS_CENTER);

function createForceFields(center: V3): ForceField[] {
  const spring = createSpringForceField({ springCenter: center, springConstant: [1, 1, 1] });
  const torus = createTorusForceField({
    center: center,
    dimensions: [10, 1, 10],
    torusRadius: 1,
    maxForces: [1, 1, 1],
  });

  const dampingConstant = [0.01, 0.01, 0.01] as V3;
  const damping = createDampingForceField(dampingConstant);

  const wind = createWindForceField({
    speed: 1 / 7000,
    periodicAmplitude: 2,
    baseStrength: 0.5,
    updateRate: 1000 / 60,
  });
  return [spring, torus, damping, wind];
}

export class ClothSettings {
  static DRAG = 1 - 0.05;
  static MASS = 1;
  static GRAVITY_FORCE = [0, -1, 0] as V3;

  static X_SEGMENTS = 6;
  static Y_SEGMENTS = 6;

  static TIME_STEP_SQUARED = (18 / 1000) ** 2;

  static WIND_BASE_STRENGTH = 0.12;
  static WIND_PERIODIC_AMPLITUDE = 0.27;
}

export const SERVER_IP = process.env.REACT_APP_API_URL || '//'+window.location.hostname+'/api';

export const DEFAULT_TEXT = [
  'is onze auto niet meer gewassen.',
  'heb ik 10 keer met voetbal gescoord!! Ik wou dat je me gezien had.',
  'begrijp ik mama een stuk beter.',
  'lijkt de dag minder licht en de nacht nog donkerder.',
  'zou ik zo graag nog even bij je willen zijn.',
  'is het steeds zo stil in huis, veel’s te stil.',
  'leest papa mij s’avonds voor, maar niet zo mooi als jij.',
  'zie ik opa en oma veel vaker, maar ik mis je toch.',
  'heb ik een cavia gekregen.',
  'kan ik niet stoppen met huilen, ik ben zo verdrietig.',
  'heb ik zo veel ruzie met mijn zus. Ze doet zooo vervelend.',
  'wil ik ook dood gaan.',
];

export const getScreenTarget = (camera: PerspectiveCamera): V3 => {
  const position = V3O.fromArray(camera.position.toArray());
  const yOffset = [0, 0.095, 0] as V3;
  const distanceScale =
    1 / Math.min((window.innerWidth / window.innerHeight) * PAPER_ASPECT_RATIO, 1);
  const centerTarget = V3O.add(position, V3O.scale(V3O.forwards(), -1.165 * distanceScale));
  const target = V3O.add(centerTarget, yOffset);
  return target;
};
