import { Dispatch } from 'components/store/actions';
import { State } from 'components/store/state';
import { TEXT_SETTINGS } from 'config';
import { canvasWriter } from 'drivers/canvas-writer';
import { PreBuiltTextures } from 'textures';
import { CanvasTexture, Geometry, Mesh, MeshPhongMaterial } from 'three';
import shallow from 'zustand/shallow';
import { useStore } from '../store/store';

const selector = (state: State & { dispatch: Dispatch }) => {
  const maybeMesh = state.chosenPaper?.tryGetComponent('MESH')?.mesh;
  const maybePaperComponent =
    state.chosenPaper?.tryGetComponent('WRITTEN_PAPER')?.state ||
    state.chosenPaper?.tryGetComponent('SERVER_PAPER')?.state;
  const isWritingPage = maybePaperComponent === 'WRITING' || maybePaperComponent === 'SUBMIT';

  return [
    state.userText,
    state.isWritingVisible,
    maybeMesh as Mesh<Geometry, MeshPhongMaterial> | undefined,
    isWritingPage,
    state.isCanvasWritingVisible,
  ] as const;
};

export const TextureUpdater = (): null => {
  const [userText, isWritingVisible, maybeMesh, isWritingPage, isCanvasWritingVisible] = useStore(
    selector,
    shallow,
  );

  const shouldUseTextureUpdater = maybeMesh !== undefined && isWritingPage && isWritingVisible;
  if (!shouldUseTextureUpdater) return null;

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const mesh = maybeMesh!;

  if (userText === '') {
    updateMeshTexture(mesh, 'placeholder');
    return null;
  }

  const shouldWriteHeader = !isCanvasWritingVisible;
  if (shouldWriteHeader) {
    updateMeshTexture(mesh, 'header');
    return null;
  }

  // Write entire text
  updateMeshTexture(mesh, 'user', userText);

  return null;
};

function updateMeshTexture(
  mesh: Mesh<Geometry, MeshPhongMaterial>,
  type: 'user' | 'placeholder' | 'header',
  text?: string,
): void {
  const { material } = mesh;

  let texture: CanvasTexture | undefined = undefined;
  switch (type) {
    case 'placeholder':
      if (material.name !== 'placeholder') texture = PreBuiltTextures.getPlaceholder();
      material.name = 'placeholder';
      break;
    case 'header':
      if (material.name !== 'header') texture = PreBuiltTextures.getHeader();
      material.name = 'header';
      break;
    case 'user':
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      texture = getTexture(text!);
      material.name = 'user';
      break;
  }
  if (!!texture) {
    material.map = texture;
    material.needsUpdate = true;
    return;
  }
}

function getTexture(userText: string) {
  canvasWriter.clearCanvas();
  canvasWriter.writeText('Sinds jij\ndood bent...');
  canvasWriter.writeTextAt(userText, TEXT_SETTINGS.userTextLineOffset);
  const texture = canvasWriter.getTexture();
  return texture;
}
