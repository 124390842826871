/* eslint-disable @typescript-eslint/no-explicit-any */
import { Reducer } from 'react';
import create, { GetState, UseStore } from 'zustand';

type Dispatch<TActions> = { dispatch: (action: TActions) => TActions };

export type AsyncDispatch<S, A> = (
  getState: () => S,
  action: A,
  dispatch: (action: A) => void,
) => void;

export const createAsyncStore = <
  TState extends Record<string | number | symbol, unknown>,
  TActions
>(
  dispatcher: AsyncDispatch<TState, TActions>,
  reducer: Reducer<TState, TActions>,
  initialState: TState,
): UseStore<TState & Dispatch<TActions>> => {
  return create<TState & Dispatch<TActions>>(
    reduxWithDispatcher(dispatcher, reducer, initialState),
  );
};

const reduxWithDispatcher = <TState extends Record<string | number | symbol, unknown>, TActions>(
  dispatcher: AsyncDispatch<TState, TActions>,
  reducer: Reducer<TState, TActions>,
  initial: TState,
) => (set: any, get: GetState<TState>, api: any) => {
  const dispatchAsync = (action: TActions) =>
    set((state: TState) => {
      return reducer(state, action);
    });
  api.dispatch = (action: TActions) => {
    dispatcher(get, action, dispatchAsync);

    set((state: TState) => {
      return reducer(state, action);
    });
    return action;
  };

  return { dispatch: api.dispatch, ...initial };
};
