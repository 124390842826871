import { CanvasTexture } from 'three';
import { MeasureText } from '../utilities/measure-text';
import { WordWrap } from './../utilities/word-wrap';

import { PAPER_COLOR, PAPER_TEXTURE_SIZE, TEXT_SETTINGS } from 'config';

class CanvasWriter {
  private width: number;
  private height: number;
  private context: CanvasRenderingContext2D;
  private wrapper: WordWrap;

  private textWidth: number;
  private textMeasurer: MeasureText;
  private textHeight: number;

  constructor(
    private canvas: HTMLCanvasElement,
    private fontSize: number,
    private font: string,
    private lineHeight: number,
    private backgroundColor: string,
    private fontColor: string,
    private paddingLeft = 0,
    private paddingRight = 0,
    private paddingTop = 0,
    private paddingBottom = 0,
  ) {
    const maybeContext = this.canvas.getContext('2d');
    if (!maybeContext) throw new Error(`Cannot create 2d context on canvas ${canvas}`);
    this.context = maybeContext;

    this.width = this.canvas.width;
    this.textWidth = this.width - this.paddingLeft - this.paddingRight;

    this.height = this.canvas.height;
    this.textHeight = this.height - this.paddingTop - this.paddingBottom;

    this.textMeasurer = new MeasureText();
    this.wrapper = new WordWrap(this.textWidth, (text) =>
      this.textMeasurer.measure(text, this.context),
    );
  }

  getTexture(): CanvasTexture {
    const url = this.canvas.toDataURL();
    const img = document.createElement('img');
    img.src = url;

    return new CanvasTexture(img);
  }

  clearCanvas(): void {
    this.context.fillStyle = this.backgroundColor;
    this.context.fillRect(0, 0, this.width, this.height);
  }

  writeText(text: string, fontColor?: string): void {
    const permanentColor = this.fontColor;
    this.fontColor = fontColor || this.fontColor;
    this.setFontStyles();
    const lines = this.getLines(text);
    this.writeLines(lines);
    this.fontColor = permanentColor;
  }

  writeTextAt(text: string, lineOffset: number, fontColor?: string) {
    const permanentColor = this.fontColor;
    this.fontColor = fontColor || this.fontColor;
    this.setFontStyles();
    const lines = this.getLines(text);
    this.writeLines(lines, lineOffset);
    this.fontColor = permanentColor;
  }

  private getLines(text: string): string[] {
    return this.wrapper.wrap(text);
  }

  private writeLines(lines: string[], lineOffset = 0): void {
    lines.forEach((line, lineNumber) => this.writeLine(line, lineNumber + lineOffset));
  }

  private writeLine(line: string, lineNumber: number): void {
    const x = this.paddingLeft;
    const y = this.paddingTop + lineNumber * this.lineHeight;

    const isValidWidth = this.isLineWithinWidthLimits(line, lineNumber);
    const isValidHeight = this.isLineWithinHeightLimits(y, line, lineNumber);
    if (isValidWidth && isValidHeight) {
      this.context.fillText(line, x, y, this.textWidth);
    }
  }

  private isLineWithinHeightLimits(y: number, line: string, lineNumber: number) {
    const limitY = this.textHeight + this.paddingTop;
    if (y <= limitY) return true;

    console.warn(
      `Should not writeLine: Line ${line} at lineNumber ${lineNumber} does not fit within height of canvas`,
    );
    return false;
  }

  private isLineWithinWidthLimits(line: string, lineNumber: number) {
    const limitX = this.context.measureText(line).width;
    if (limitX <= this.textWidth) return true;

    console.warn(
      `Should not writeLine: Line ${line} at lineNumber ${lineNumber} does not fit within width of canvas`,
    );
    return false;
  }

  private setFontStyles(): void {
    this.context.font = `${this.fontSize}px ${this.font}`;
    this.context.fillStyle = this.fontColor;
    this.context.textBaseline = 'alphabetic';
  }
}

function createCanvasWriter(): CanvasWriter {
  const canvas = document.createElement('canvas');

  const { width, height } = PAPER_TEXTURE_SIZE;
  canvas.width = width;
  canvas.height = height;

  const fontSize = TEXT_SETTINGS.fontSize;
  const lineHeight = TEXT_SETTINGS.lineHeight;
  const backgroundColor = PAPER_COLOR;
  const font = TEXT_SETTINGS.font;
  const canvasWriter = new CanvasWriter(
    canvas,
    fontSize * canvas.width,
    font,
    lineHeight * canvas.width,
    backgroundColor,
    TEXT_SETTINGS.fontColor,
    TEXT_SETTINGS.paddingLeft * canvas.width,
    TEXT_SETTINGS.paddingRight * canvas.width,
    TEXT_SETTINGS.paddingTop * canvas.width,
    TEXT_SETTINGS.paddingBottom * canvas.width,
  );

  return canvasWriter;
}

export const canvasWriter = createCanvasWriter();
