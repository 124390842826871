import { Dispatch } from 'components/store/actions';
import { State } from 'components/store/state';
import { useStore } from 'components/store/store';
import {
  FORCES,
  OFFSCREEN_POSITION,
  PAPER_BUFFER_GEOMETRY,
  PAPER_COLOR,
  PAPER_MASS,
  STARTING_VELOCITY,
} from 'config';
import { BufferedDisplayComponent } from 'engine/display/buffered-display-component';
import { MeshComponent } from 'engine/mesh/mesh-component';
import { WrittenPaperComponent } from 'engine/paper/written-paper-component';
import { PhysicsComponent } from 'engine/physics/physics-component';
import { TransformComponent } from 'engine/transform/transform-component';
import { V3O } from 'engine/vectors/v3';
import React, { useMemo } from 'react';
import { useThree } from 'react-three-fiber';
import { PreBuiltTextures } from 'textures';
import { Mesh, MeshPhongMaterial, PerspectiveCamera } from 'three';
import shallow from 'zustand/shallow';

const selector = (state: State & { dispatch: Dispatch }) =>
  [state.writtenPapers, state.dispatch] as const;

export function WrittenPapers(): React.ReactElement {
  const [writtenPapers, dispatch] = useStore(selector, shallow);
  const { camera } = useThree();
  const meshes = useMemo(() => {
    const onWritingAnimationComplete = () => dispatch({ type: 'START_WRITING' });
    const meshes = writtenPapers.map((_, index) => {
      const material = new MeshPhongMaterial({
        transparent: true,
        color: PAPER_COLOR,
        map: PreBuiltTextures.getPlaceholder(),
        toneMapped: false,
      });
      if (material === undefined) throw new Error(`Cannot get material for index ${index}.`);
      return new Mesh(PAPER_BUFFER_GEOMETRY, material);
    });

    writtenPapers.forEach((entity, index) => {
      const mesh = meshes[index];
      entity.removeComponent('MESH');
      entity.setComponent(MeshComponent, entity, mesh);

      const position = OFFSCREEN_POSITION;
      const rotation = V3O.zero();
      const velocity = V3O.randomRange(-STARTING_VELOCITY / 2, STARTING_VELOCITY / 2);
      const mass = PAPER_MASS;
      entity.trySetComponent(TransformComponent, entity, {
        position,
        rotation,
      });
      entity.trySetComponent(PhysicsComponent, entity, {
        velocity,
        mass: mass,
        dynamic: false,
        forces: FORCES,
      });
      entity.trySetComponent(BufferedDisplayComponent, entity);
      entity.trySetComponent(
        WrittenPaperComponent,
        entity,
        camera as PerspectiveCamera,
        onWritingAnimationComplete,
      );
    });
    return meshes;
  }, [writtenPapers, dispatch, camera]);

  const jsx = meshes.map((mesh, index) => <primitive key={index} object={mesh}></primitive>);

  return <>{jsx}</>;
}
