import { Entity } from '../entity';

export class Display {
  static update(entities: Entity[]): void {
    for (let index = 0; index < entities.length; index++) {
      const entity = entities[index];
      entity.tryGetComponent('BUFFERED_DISPLAY')?.update();
    }
  }
}
