import { PerspectiveCamera, Vector3 } from 'three';
import { V2 } from 'engine/vectors/v2';
import { V3, V3O } from 'engine/vectors/v3';

export const screenToWorld = (mouse: V2, camera: PerspectiveCamera, z: number): V3 => {
  const vec = new Vector3(...mouse, z);
  vec.unproject(camera);
  vec.sub(camera.position).normalize();
  const distance = -camera.position.z / vec.z;

  return V3O.fromArray(
    new Vector3().copy(camera.position).add(vec.multiplyScalar(distance)).toArray(),
  );
};
