import bg1 from './1.png';
import bg2 from './2.png';
import bg3 from './3.png';
import bg4 from './4.png';
import bg5 from './5.png';
import bg6 from './6.png';
import bg7 from './7.png';
import bg8 from './8.png';
import bg9 from './9.png';
import bg10 from './10.png';
import { Color } from 'three';

export const environments = [
  { background: bg1, ambient: new Color('#BBC0FF'), directional: new Color('#AF8421') },
  { background: bg2, ambient: new Color('#A2B4FD'), directional: new Color('#96A0CB') },
  { background: bg3, ambient: new Color('#90B6EC'), directional: new Color('#9DB58F') },
  { background: bg4, ambient: new Color('#F9D990'), directional: new Color('#9B9C9E') },
  { background: bg5, ambient: new Color('#FFBB81'), directional: new Color('#C99850') },
  { background: bg6, ambient: new Color('#F9E299'), directional: new Color('#CBC496') },
  { background: bg7, ambient: new Color('#FFB866'), directional: new Color('#FFECB8') },
  { background: bg8, ambient: new Color('#F993B0'), directional: new Color('#F7C143') },
  { background: bg9, ambient: new Color('#A1AFF5'), directional: new Color('#96895A') },
  { background: bg10, ambient: new Color('#C9B3DC'), directional: new Color('#6A6AA8') },
];
