import { useStore } from 'components/store/store';
import {
  FORCES,
  PAPERS_CENTER,
  PAPER_BUFFER_GEOMETRY,
  PAPER_MASS,
  STARTING_VELOCITY,
  SWIPE_DISTANCE,
  SERVER_IP,
  DEFAULT_TEXT,
} from 'config';
import { BufferedDisplayComponent } from 'engine/display/buffered-display-component';
import { MeshComponent } from 'engine/mesh/mesh-component';
import { PhysicsComponent } from 'engine/physics/physics-component';
import { TransformComponent } from 'engine/transform/transform-component';
import { V3O } from 'engine/vectors/v3';
import React, { useEffect } from 'react';
import { useThree } from 'react-three-fiber';
import { Mesh, PerspectiveCamera } from 'three';
import shallow from 'zustand/shallow';
import axios from 'axios';
import { ServerPaperComponent } from 'engine/paper/server-paper-component';

const defaultText = [
  ...DEFAULT_TEXT,
  ...DEFAULT_TEXT,
  ...DEFAULT_TEXT,
  ...DEFAULT_TEXT,
  ...DEFAULT_TEXT,
  ...DEFAULT_TEXT,
  ...DEFAULT_TEXT,
  ...DEFAULT_TEXT,
];

export function ServerPapers(): React.ReactElement {
  const [serverMaterials, serverPapers, dispatch] = useStore(
    (state) => [state.serverMaterials, state.serverPapers, state.dispatch],
    shallow,
  );

  useEffect(() => {
    dispatch({ type: 'SET_SERVER_TEXTS', payload: defaultText });
    axios
      .get(SERVER_IP + '/fetch-confirmed.php', { timeout: 600 })
      .then(({ data }: { data: string[] }) => {
        dispatch({ type: 'SET_SERVER_TEXTS', payload: data });
      })
      .catch((reason) => {
        console.error('Could not get server papers: using default text' + reason);
      });
  }, [dispatch]);

  const { viewport, camera } = useThree();

  const meshes = serverPapers.map((_, index) => {
    const material = serverMaterials[index % serverMaterials.length];
    return new Mesh(PAPER_BUFFER_GEOMETRY, material);
  });

  serverPapers.forEach((entity, index) => {
    const mesh = meshes[index];
    entity.removeComponent('MESH');
    entity.setComponent(MeshComponent, entity, mesh);
    const position = V3O.add(V3O.randomRange(-viewport.width, viewport.width), PAPERS_CENTER);
    const rotation = V3O.randomRange(-viewport.width, viewport.width);
    const velocity = V3O.randomRange(-STARTING_VELOCITY / 2, STARTING_VELOCITY / 2);
    const mass = PAPER_MASS;
    const swipeDistance = SWIPE_DISTANCE * window.innerHeight;
    entity.trySetComponent(TransformComponent, entity, {
      position,
      rotation,
    });
    entity.trySetComponent(PhysicsComponent, entity, {
      velocity,
      mass: mass,
      dynamic: true,
      forces: FORCES,
    });
    entity.trySetComponent(BufferedDisplayComponent, entity);
    entity.trySetComponent(
      ServerPaperComponent,
      entity,
      swipeDistance,
      camera as PerspectiveCamera,
    );
  });

  const jsx = meshes.map((mesh, index) => <primitive key={index} object={mesh}></primitive>);

  return <>{jsx}</>;
}
