import { V3, V3O } from 'engine/vectors/v3';
import { Entity } from '../entity';
import { applyAcceleration } from './acceleration';
import { AccelerationField, applyAccelerationFields } from './fields/acceleration-field';
import { applyForceFields, ForceField } from './fields/force-field';
import { applyForce } from './force';
import { applyVelocity } from './velocity';

export class Physics {
  static update(entities: Entity[], timeStep: number): void {
    for (let index = 0; index < entities.length; index++) {
      const entity = entities[index];
      Physics.updateEntity(entity, timeStep);
    }
  }

  private static updateEntity(entity: Entity, timeStep: number) {
    const component = entity.tryGetComponent('PHYSICS');
    const transform = entity.tryGetComponent('TRANSFORM')?.transform;
    if (!!component && component.dynamic && !!transform) {
      const { position, rotation } = transform;

      const { mass, velocity, forces, accelerations } = component;

      const force = Physics.collectForces(position, velocity, forces);
      const forceAcceleration = applyForce(force, mass);
      const acceleration = Physics.collectAccelerations(forceAcceleration, accelerations);

      const accelerationVelocity = applyAcceleration(velocity, acceleration, timeStep);
      const newVelocity = Physics.collectVelocities(accelerationVelocity);
      const newPosition = applyVelocity(position, velocity, timeStep);
      const newRotation = Physics.getRotation(rotation, velocity);

      transform.position = newPosition;
      transform.rotation = newRotation;
      component.velocity = newVelocity;
    }
  }

  private static getRotation(currentRotation: V3, targetRotation: V3) {
    const rotation = V3O.lerpTheta(currentRotation, targetRotation, 0.01);
    return rotation;
  }

  private static collectAccelerations(
    accelerationFromForce: V3,
    accelerationFields: AccelerationField[],
  ) {
    const accelerations = applyAccelerationFields(accelerationFields);
    accelerations.push(accelerationFromForce);
    const acceleration = V3O.sum(accelerations);
    return acceleration;
  }

  private static collectVelocities(velocityFromAcceleration: V3) {
    const velocity = velocityFromAcceleration;
    return velocity;
  }

  private static collectForces(position: V3, velocity: V3, forceFields: ForceField[]) {
    const forces = applyForceFields(forceFields, position, velocity);
    const force = V3O.sum(forces);
    return force;
  }
}
