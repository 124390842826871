import React, { useCallback, useEffect, useRef, useState } from 'react';
import 'styles/colophon.css';
import { useStore } from 'components/store/store';
import logo from 'images/logo.svg';
import close from 'images/close_button_share_page.svg';

export const Colophon = ({ onClose }: { onClose: () => void }): React.ReactElement | null => {
  const lights = useStore((state) => state.lights);
  const currentDirectional = useRef<string>('#' + lights.directional.getHexString());
  const currentAmbient = useRef<string>('#' + lights.ambient.getHexString());
  const canvasRef = useRef<HTMLCanvasElement>(document.createElement('canvas'));

  const [isColophonRendered, setColophonRendered] = useState(false);

  useEffect(() => {
    setColophonRendered(true);
  }, []);

  useAnimationFrame(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    if (context === null) return;
    const nextDirectional = '#' + lights.directional.getHexString();
    const nextAmbient = '#' + lights.ambient.getHexString();
    const shouldDraw =
      nextDirectional !== currentDirectional.current || nextAmbient !== currentAmbient.current;
    if (shouldDraw) {
      const gradient = context.createLinearGradient(0, 0, 0, canvas.height);
      gradient.addColorStop(0, nextDirectional);
      gradient.addColorStop(1, nextAmbient);

      context.fillStyle = gradient;
      context.fillRect(0, 0, canvas.width, canvas.height);
      currentAmbient.current = nextAmbient;
      currentDirectional.current = nextDirectional;
    }
  });

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    if (context === null) return;
    const gradient = context.createLinearGradient(0, 0, 0, canvas.height);
    gradient.addColorStop(0, currentDirectional.current);
    gradient.addColorStop(1, currentAmbient.current);

    context.fillStyle = gradient;
    context.fillRect(0, 0, canvas.width, canvas.height);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canvasRef.current.getContext('2d')]);

  return (
    <>
      <canvas
        ref={canvasRef}
        className={`colophon-canvas ${
          isColophonRendered ? 'colophon-onscreen' : 'colophon-offscreen'
        }`}
      ></canvas>
      <div
        className={`colophon-container ${
          isColophonRendered ? 'colophon-onscreen' : 'colophon-offscreen'
        }`}
      >
        <div className="colophon" tabIndex={-1}>
          <div className="colophon-text">
            Als er iemand dood gaat, is het soms lastig te begrijpen hoe je je voelt. En dat is heel
            normaal. Misschien ben je heel verdrietig, of woedend, of wil je gewoon leuke dingen
            gaan doen. Misschien voel je je alleen of ben je bang dat je gaat vergeten hoe iemand
            eruitzag. Of misschien voel je al die dingen door elkaar.
            <div className="line-space1"></div>
            Hier kun jij opschrijven wat jij vindt of voelt. Dat kan elke dag iets anders zijn. Wat
            je schrijft is anoniem, dus niemand weet dat het van jou is.
            <div className="line-space2"></div>
            Wil je hulp, informatie of praten met de Kindertelefoon? Ga dan naar{' '}
            <a href="https://hetklokhuis.nl/doodenafscheid" target="_blank" rel="noreferrer">
              Het Klokhuis over dood en afscheid
            </a>
            <div className="logo">
              <img src={logo} alt="logo"></img>
            </div>
            <div className="line-space4"></div>
            Concept: Moniker
            <br />
            Productie: Moniker, <br />
            Luna Maurer & Roel Wouters
            <br />
            Co-productie: Het Klokhuis
            <br />
            Ontwerp: Moniker, Jolana Sykorova
            <br />
            Technische realisering: Jae Perris
            <br />
            Sound design: Volcano Pork, Simon Wald-Lasowski & Sajoscha Talirz
          </div>

          <div
            className="colophon-close-button"
            onClick={() => {
              onClose();
            }}
            onTouchEnd={(event) => {
              event.preventDefault();
              onClose();
            }}
            role="button"
            tabIndex={-1}
            onKeyPress={() => {
              onClose();
            }}
          >
            <img className="colophon-close-button-icon" src={close} alt={''}></img>
          </div>
        </div>
      </div>
    </>
  );
};

const useAnimationFrame = (callback: (deltaTime: number) => void) => {
  const requestRef = React.useRef<number>();
  const previousTimeRef = React.useRef<number>();

  const animate = useCallback(
    (time: number) => {
      if (previousTimeRef.current !== undefined) {
        const deltaTime = time - previousTimeRef.current;
        callback(deltaTime);
      }
      previousTimeRef.current = time;
      requestRef.current = requestAnimationFrame(animate);
    },
    [callback],
  );

  React.useEffect(() => {
    requestRef.current = requestAnimationFrame(animate);
    return () => {
      requestRef.current && cancelAnimationFrame(requestRef.current);
    };
  }, [animate]);
};
