import { Component } from '../component';
import { Entity } from '../entity';
import { V3, V3O } from '../vectors/v3';
import { AccelerationField } from './fields/acceleration-field';
import { ForceField } from './fields/force-field';

interface PhysicsEntityData {
  velocity: V3;
  forces: ForceField[];
  accelerations: AccelerationField[];
  dynamic: boolean;
  mass: number;
}

const createDefaultPhysicsEntityData = (): PhysicsEntityData => {
  return {
    velocity: V3O.zero(),
    forces: [],
    accelerations: [],
    dynamic: true,
    mass: 1,
  };
};

export class PhysicsComponent extends Component {
  velocity: V3;
  forces: ForceField[];
  accelerations: AccelerationField[];
  dynamic: boolean;
  mass: number;

  constructor(
    parent: Entity,
    {
      velocity = V3O.zero(),
      forces = [],
      accelerations = [],
      dynamic = true,
      mass = 1,
    }: Partial<PhysicsEntityData> = createDefaultPhysicsEntityData(),
  ) {
    super({ type: 'PHYSICS', parent, dependencies: ['TRANSFORM'] });

    this.velocity = velocity;
    this.forces = forces;
    this.accelerations = accelerations;
    this.dynamic = dynamic;
    this.mass = mass;
  }
}
