import React, { useEffect, useState } from 'react';
import { environments } from '../../images/environments';

import 'styles/background.css';
import { useStore } from 'components/store/store';
import { ENVIRONMENT_CHANGE_DURATION, ENVIRONMENT_CHANGE_INTERVAL } from 'config';

const randomBackgroundStart = Math.floor(Math.random() * environments.length);
const startSelected = (randomBackgroundStart + 1) % environments.length;
const startNext = (startSelected + 1) % environments.length;

const Background = (): React.ReactElement => {
  const dispatch = useStore((state) => state.dispatch);
  const [[lastImage, selectedImage, nextImage], selectImage] = useState([
    randomBackgroundStart,
    startSelected,
    startNext,
  ]);

  useEffect(() => {
    dispatch({ type: 'CHANGE_ENVIRONMENT', payload: environments[selectedImage] });

    const interval = setInterval(() => {
      const last = selectedImage;
      const current = nextImage;
      const next = (current + 1) % environments.length;
      dispatch({ type: 'CHANGE_ENVIRONMENT', payload: environments[current] });
      selectImage([last, current, next]);
    }, ENVIRONMENT_CHANGE_INTERVAL);

    return () => clearInterval(interval);
  });

  const Last = () => (
    <div
      key={nextImage}
      style={{
        backgroundImage: `url(${environments[lastImage].background})`,
        opacity: 1,
        transition: `opacity ${ENVIRONMENT_CHANGE_DURATION}ms`,
      }}
      className="Background"
    ></div>
  );
  const backgrounds = environments.map((environment, index) => (
    <div
      key={index}
      style={{
        backgroundImage: `url(${environment.background})`,
        opacity: selectedImage === index ? '1' : '0',
        transition: `opacity ${ENVIRONMENT_CHANGE_DURATION}ms`,
      }}
      className="Background"
    ></div>
  ));

  return (
    <>
      <Last />
      {backgrounds}
      <div className="background-gradient" />
    </>
  );
};

export { Background };
