import { V3, V3O } from '../vectors/v3';

interface TransformData {
  position?: V3;
  rotation?: V3;
}

export class Transform implements TransformData {
  position: V3;
  rotation: V3;

  static zero(): Transform {
    return new Transform({ position: V3O.zero(), rotation: V3O.zero() });
  }

  constructor({ position = V3O.zero(), rotation = V3O.zero() }: TransformData = Transform.zero()) {
    this.position = V3O.copy(position);
    this.rotation = V3O.copy(rotation);
  }
}
