import { State } from 'components/store/state';
import { useStore } from 'components/store/store';
import { Animations } from 'engine/animations/animations';
import { Display } from 'engine/display/display';
import { Physics } from 'engine/physics/physics';
import { CanvasContext, useFrame } from 'react-three-fiber';
import { Entity } from 'engine/entity';
import shallow from 'zustand/shallow';
import { Cloth } from 'engine/cloth/cloth';

const selector = (state: State) => [...state.serverPapers, ...state.writtenPapers];

export const Engine = (): null => {
  const entities = useStore(selector, shallow);

  function engineLoop(_: CanvasContext, delta: number) {
    animationLoop(entities);
    physicsLoop(delta, entities);
    displayLoop(entities);
    clothLoop(entities);
  }

  useFrame(engineLoop);

  return null;
};

function physicsLoop(delta: number, entities: Entity[]) {
  const MAXIMUM_FRAME_TIME = 1; // Seconds
  // When the browser tab isn't seen anymore (minimized or completely covered) the simulation shouldn't run
  const shouldSkipFrame = delta > MAXIMUM_FRAME_TIME;
  if (shouldSkipFrame) return;
  const frameRate = 1 / delta;
  if (frameRate < 20) {
    Physics.update(entities, 1 / 40);
  } else {
    Physics.update(entities, delta);
  }
}

function animationLoop(entities: Entity[]) {
  Animations.update(entities);
}

function displayLoop(entities: Entity[]) {
  Display.update(entities);
}

function clothLoop(entities: Entity[]) {
  Cloth.update(entities);
}
