import { Dispatch } from 'components/store/actions';
import { State } from 'components/store/state';
import { getScreenTarget, PAPER_GEOMETRY } from 'config';
import React, { useEffect, useRef } from 'react';
import { useThree } from 'react-three-fiber';
import {
  Geometry,
  Mesh,
  MeshPhongMaterial,
  PerspectiveCamera,
  Vector2,
  Box2,
  Vector3,
} from 'three';
import { useStore } from '../store/store';

const selector = (state: State & { dispatch: Dispatch }) => state.dispatch;

export const PaperMeasurer = (): React.ReactElement | null => {
  const { camera } = useThree();
  const dispatch = useStore(selector);

  const ref = useRef<Mesh<Geometry, MeshPhongMaterial>>();
  const position = getScreenTarget(camera as PerspectiveCamera);

  useEffect(() => {
    const mesh = ref.current;
    if (!mesh) return;
    const size = getPaperSize(mesh, camera as PerspectiveCamera);
    dispatch({
      type: 'SET_PAPER_DIMENSIONS',
      payload: size,
    });
  });

  return (
    <mesh ref={ref} position={[...position]} geometry={PAPER_GEOMETRY} key={0} visible={false} />
  );
};

const getPaperSize = function (mesh: Mesh<Geometry>, camera: PerspectiveCamera) {
  mesh.updateMatrixWorld();
  camera.updateMatrixWorld();
  camera.updateProjectionMatrix();

  const box = computeScreenSpaceBoundingBox(mesh, camera);
  const { x: width, y: height } = normalizedToPixels(
    box.getSize(new Vector2()),
    window.innerWidth,
    window.innerHeight,
  );

  const { y: yOffset } = normalizedToPixels(
    box.getCenter(new Vector2()),
    window.innerWidth,
    window.innerHeight,
  );

  const left = (window.innerWidth - width) / 2;
  const right = left + width;
  const top = (window.innerHeight - height) / 2 - yOffset;
  const bottom = top + height;

  return { left, right, top, bottom, width, height };
};

function computeScreenSpaceBoundingBox(mesh: Mesh<Geometry>, camera: PerspectiveCamera): Box2 {
  const vertices = mesh.geometry.vertices;
  const vertex = new Vector3();
  const min = new Vector3(1, 1, 1);
  const max = new Vector3(-1, -1, -1);

  for (let i = 0; i < vertices.length; i++) {
    const vertexWorldCoord = vertex.copy(vertices[i]).applyMatrix4(mesh.matrixWorld);
    const vertexScreenSpace = vertexWorldCoord.project(camera);
    min.min(vertexScreenSpace);
    max.max(vertexScreenSpace);
  }

  return new Box2(new Vector2().set(min.x, min.y), new Vector2().set(max.x, max.y));
}

function normalizedToPixels(
  coord: Vector2,
  renderWidthPixels: number,
  renderHeightPixels: number,
): Vector2 {
  const halfScreen = new Vector2(renderWidthPixels / 2, renderHeightPixels / 2);
  return coord.clone().multiply(halfScreen);
}
