import React, { useState } from 'react';
import 'styles/ui.css';
import { useStore } from '../store/store';
import shallow from 'zustand/shallow';
import { State } from 'components/store/state';
import { Dispatch } from 'components/store/actions';
import { FADE_TIME, INTRO_HOLD_TIME } from 'config';
import plusButton from 'images/plus_button.svg';
import colophonButton from 'images/colophon_button.svg';
import { Colophon } from './colophon';
import { Share } from './share';
import { useMobileDetect } from 'utilities/use-mobile-detect-hook';

const selector = (state: State & { dispatch: Dispatch }) =>
  [
    state.isIntroVisible,
    state.isGetWritingPaperVisible,
    state.isColophonButtonVisible,
    state.isShareButtonVisible,
    state.isSubmitButtonVisible,
    state.dispatch,
  ] as const;

export const UI = (): React.ReactElement | null => {
  const { isMobile } = useMobileDetect();

  const { height, top } = useStore((state) => state.paperDimensions, shallow);
  const [
    isIntroVisible,
    isGetWritingPaperVisible,
    isColophonButtonVisible,
    isShareButtonVisible,
    isSubmitButtonVisible,
    dispatch,
  ] = useStore(selector, shallow);
  const [isColophonVisible, setColophonVisible] = useState(false);
  const [isShareVisible, setShareVisible] = useState(false);

  const injectedStyle: React.CSSProperties = {
    opacity: !isIntroVisible ? 1 : 0,
    transition: `opacity ${FADE_TIME}s`,
    transitionDelay: `${INTRO_HOLD_TIME}ms`,
    touchAction: !isIntroVisible ? 'auto' : 'none',
    pointerEvents: !isIntroVisible ? 'auto' : 'none',
    height: window.innerHeight - top - height + 'px',
    display: isShareVisible || isColophonVisible ? 'none' : 'block',
  };

  const onColophonOpen = () => setColophonVisible(true);
  const onColophonClose = () => setColophonVisible(false);
  const onShareOpen = () => setShareVisible(true);
  const onShareClose = () => setShareVisible(false);

  return (
    <>
      <div className={'ui'} style={injectedStyle}>
        {isColophonButtonVisible && (
          <div
            className="open-colophon"
            onClick={(event) => {
              onColophonOpen();
              event.preventDefault();
            }}
            onTouchEnd={(event) => {
              onColophonOpen();
              event.preventDefault();
            }}
            role="button"
            tabIndex={0}
            key={0}
            onKeyPress={(event) => {
              onColophonOpen();
              event.preventDefault();
            }}
          >
            <img className="colophon-open-button" src={colophonButton} alt={''}></img>
          </div>
        )}
        {isGetWritingPaperVisible && (
          <>
            <div
              className="plus-button-click-area"
              onClick={() => dispatch({ type: 'GET_WRITING_PAGE' })}
              onTouchEnd={(event) => {
                event.preventDefault();
                return dispatch({ type: 'GET_WRITING_PAGE' });
              }}
              role="button"
              tabIndex={0}
              key={1}
              onKeyPress={() => dispatch({ type: 'GET_WRITING_PAGE' })}
            ></div>
            <img className="plus-button" src={plusButton} alt={''}></img>
          </>
        )}
        {isSubmitButtonVisible && (
          <div
            className="release"
            onClick={() => dispatch({ type: 'RELEASE_PAPER' })}
            onTouchEnd={(event) => {
              event.preventDefault();
              return dispatch({ type: 'RELEASE_PAPER' });
            }}
            role="button"
            tabIndex={0}
            key={2}
            onKeyPress={() => dispatch({ type: 'RELEASE_PAPER' })}
          >
            {isMobile() ? 'Veeg om los te laten' : 'Klik om los te laten'}
          </div>
        )}
        {isShareButtonVisible && (
          <div
            className="open-share"
            onClick={(event) => {
              onShareOpen();
              event.preventDefault();
            }}
            onTouchEnd={(event) => {
              onShareOpen();
              event.preventDefault();
            }}
            role="button"
            tabIndex={0}
            key={3}
            onKeyPress={(event) => {
              onShareOpen();
              event.preventDefault();
            }}
          >
            delen
          </div>
        )}
      </div>

      {isColophonVisible && <Colophon onClose={onColophonClose} />}
      {isShareVisible && <Share onClose={onShareClose} />}
    </>
  );
};
