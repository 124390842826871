import { DEFAULT_TEXT } from './config';
import { CanvasTexture } from 'three';
import { canvasWriter } from './drivers/canvas-writer';
import { TEXT_SETTINGS } from 'config';

export class PreBuiltTextures {
  private static header: CanvasTexture | undefined;
  private static placeholder: CanvasTexture | undefined;
  private static serverTemplate: CanvasTexture | undefined;

  static getHeader(): CanvasTexture {
    if (!!this.header) return this.header;

    canvasWriter.clearCanvas();
    PreBuiltTextures.writeHeading();
    const texture = canvasWriter.getTexture();

    this.header = texture;

    return texture;
  }

  private static writeHeading() {
    canvasWriter.writeText('Sinds jij\ndood bent...');
  }

  static getPlaceholder(): CanvasTexture {
    if (!!this.placeholder) return this.placeholder;

    canvasWriter.clearCanvas();
    PreBuiltTextures.writeHeading();
    PreBuiltTextures.writeUserText('Schrijf hier verder aan deze zin');
    const texture = canvasWriter.getTexture();

    this.placeholder = texture;

    return texture;
  }
  private static writeUserText(userText: string) {
    canvasWriter.writeTextAt(userText, TEXT_SETTINGS.userTextLineOffset, 'grey');
  }

  static getServerTemplate(): CanvasTexture {
    if (!!this.serverTemplate) return this.serverTemplate;

    canvasWriter.clearCanvas();
    PreBuiltTextures.writeHeading();
    PreBuiltTextures.writeUserText(DEFAULT_TEXT[0]);
    const texture = canvasWriter.getTexture();

    this.serverTemplate = texture;

    return texture;
  }
}
