import { OFFSCREEN_POSITION } from './../../config';
import { ClothComponent } from 'engine/cloth/cloth-component';
/* eslint-disable react/no-direct-mutation-state */
import { PerspectiveCamera } from 'three';
import { Component } from '../component';
import { Entity } from '../entity';
import { V3O, V3 } from 'engine/vectors/v3';
import { TimedAnimationComponent } from 'engine/animations/timed-animation-component';
import { V2O, V2 } from 'engine/vectors/v2';
import { screenToWorld } from 'utilities/screen-to-world';
import { getScreenTarget } from 'config';

export class WrittenPaperComponent extends Component {
  progress = 0;
  private dragDistance = V2O.zero();
  private startPosition = this.getTransform().startPosition;
  private startRotation = this.getTransform().startRotation;
  public state: 'CHOSEN' | 'IDLE' | 'WRITING' | 'OFFSCREEN' | 'SUBMIT' = 'OFFSCREEN';

  constructor(
    parent: Entity,
    private camera: PerspectiveCamera,
    private onWritingAnimationComplete?: () => void,
  ) {
    super({ type: 'WRITTEN_PAPER', parent, dependencies: ['MESH', 'TRANSFORM', 'PHYSICS'] });
  }

  setDragDistance(drag: V2): void {
    this.dragDistance = drag;
  }

  isChosen(): boolean {
    return this.state === 'CHOSEN';
  }

  enterState(state: WrittenPaperComponent['state']): void {
    this.validateDependencies();

    const shouldEnter = state !== this.state;
    if (!shouldEnter) return;

    switch (state) {
      case 'CHOSEN':
        this.enterChosen();
        break;
      case 'IDLE':
        this.enterIdle();
        break;
      case 'WRITING':
        this.enterWriting();
        break;
      case 'SUBMIT':
        this.enterSubmit();
        break;
      case 'OFFSCREEN':
        this.enterOffscreen();
        break;
    }
  }

  private setStartTransform() {
    const { position, rotation } = this.parent.getComponent('TRANSFORM').transform;
    this.startPosition = position;
    this.startRotation = rotation;
  }

  private enterIdle(): void {
    this.removeAnimation();
    this.resumePhysics();
    this.progress = 0;
    this.addFlickVelocity();

    this.parent.tryGetComponent('CLOTH')?.animateRemoveClothEffect();

    this.state = 'IDLE';
  }

  private enterOffscreen(): void {
    this.removeAnimation();
    this.setStartTransform();

    this.parent.setComponent(TimedAnimationComponent, this.parent, {
      curve: 'easeInQuad',
      durationMillis: 750,
      progress: 0,
      onProgress: (entity, component, progress): void => {
        this.setTransform({
          target: [OFFSCREEN_POSITION[0], OFFSCREEN_POSITION[1], getScreenTarget(this.camera)[2]],
          targetRotation: V3O.zero(),
          progress: progress,
        });
      },
      onComplete: (entity, animation) => {
        animation.stop();
        this.parent.tryGetComponent('CLOTH')?.removeClothEffect();
      },
    });

    this.state = 'OFFSCREEN';
  }

  private enterChosen(): void {
    this.removeAnimation();
    this.resetDragDistance();

    this.parent.trySetComponent(ClothComponent, this.parent);
    this.parent.setComponent(TimedAnimationComponent, this.parent, {
      curve: 'easeInOutCubic',
      durationMillis: 200,
      progress: this.progress,
      onProgress: (entity, component, progress): void => {
        const screenTarget = getScreenTarget(this.camera);
        const target = this.getTargetWithSmallDragMovements(screenTarget);
        const targetRotation = this.getTargetRotationWithSmallDragMovements();
        this.setTransform({
          target,
          targetRotation,
          progress,
        });
        this.progress = progress;
      },
    });
    this.state = 'CHOSEN';
  }
  private enterSubmit(): void {
    this.removeAnimation();
    this.resetDragDistance();
    this.setStartTransform();

    this.parent.trySetComponent(ClothComponent, this.parent, 0);

    this.parent.setComponent(TimedAnimationComponent, this.parent, {
      curve: 'easeInOutCubic',
      durationMillis: 1000,
      progress: 0,
      onProgress: (entity, component, progress): void => {
        const screenTarget = getScreenTarget(this.camera);
        const target = this.getTargetWithSmallDragMovements(screenTarget);
        const targetRotation = this.getTargetRotationWithSmallDragMovements();
        this.setTransform({
          target,
          targetRotation,
          progress,
        });
        this.parent.getComponent('CLOTH').timeStepScale = progress;
        this.progress = progress;
      },
    });
    this.state = 'SUBMIT';
  }

  private enterWriting(): void {
    this.stopPhysics();
    this.removeAnimation();
    this.resetDragDistance();
    this.setTransform({
      target: [OFFSCREEN_POSITION[0], OFFSCREEN_POSITION[1], getScreenTarget(this.camera)[2]],
      progress: 1,
      targetRotation: V3O.zero(),
    });
    this.setStartTransform();

    this.parent.setComponent(TimedAnimationComponent, this.parent, {
      curve: 'easeOutCubic',
      durationMillis: 1000,
      progress: 0,
      onProgress: (entity, component, progress): void => {
        const screenTarget = getScreenTarget(this.camera);
        const targetRotation = V3O.zero();
        this.setTransform({
          target: screenTarget,
          targetRotation,
          progress,
        });
      },
      onComplete: (entity, animation) => {
        if (!this.onWritingAnimationComplete)
          throw new Error(
            `Could not find callback. If this was meant to be an user paper this is an error`,
          );
        this.onWritingAnimationComplete();
      },
    });
    this.state = 'WRITING';
  }

  private setTransform({
    target,
    progress,
    targetRotation,
  }: {
    target: V3;
    progress: number;
    targetRotation: V3;
  }) {
    const transform = this.parent.getComponent('TRANSFORM').transform;
    transform.position = V3O.lerp(this.startPosition, target, progress);
    transform.rotation = V3O.lerpTheta(this.startRotation, targetRotation, progress);
  }

  private getTargetRotationWithSmallDragMovements() {
    const lerpSteps = 0.1;
    const targetRotationWithWobble = V3O.lerpTheta(
      this.getTransform().startRotation,
      this.addRotationOffset(V3O.zero()),
      lerpSteps,
    );

    return addWind(targetRotationWithWobble);
  }

  private getTargetWithSmallDragMovements(screenTarget: V3) {
    const lerpSteps = 0.1;
    const targetWithWobble = V3O.lerp(
      this.getTransform().startPosition,
      this.addDragOffset(screenTarget),
      lerpSteps,
    );

    return addWind(targetWithWobble);
  }

  private addFlickVelocity() {
    const flickVelocity = 25;
    const zVelocity = 0;
    this.parent.getComponent('PHYSICS').velocity = [
      (-this.dragDistance[0] / window.innerWidth) * flickVelocity,
      (this.dragDistance[1] / window.innerHeight) * flickVelocity,
      zVelocity,
    ];
  }

  private resetDragDistance() {
    this.dragDistance = V2O.zero();
  }

  private removeAnimation() {
    this.parent.tryGetComponent('ANIMATION')?.stop();
    this.parent.removeComponent('ANIMATION');
  }

  private addDragOffset(vector: V3): V3 {
    const worldScaledDragDistance = V2O.flipX(
      V2O.divide(this.dragDistance, [window.innerWidth, window.innerHeight] as V2),
    );
    const offset = V3O.scale(screenToWorld(worldScaledDragDistance, this.camera, vector[2]), 0.05);

    return V3O.add(vector, [offset[0], offset[1], 0]);
  }

  private addRotationOffset(vector: V3): V3 {
    const worldScaledDragDistance = V2O.flipX(
      V2O.divide(this.dragDistance, [window.innerWidth, window.innerHeight] as V2),
    );
    const offset = V3O.scale(screenToWorld(worldScaledDragDistance, this.camera, vector[2]), 0.05);

    return V3O.add(vector, [offset[0], offset[1], offset[0] + offset[1]]);
  }

  private getTransform() {
    const startPosition = this.parent.getComponent('TRANSFORM').transform.position;
    const startRotation = this.parent.getComponent('TRANSFORM').transform.rotation;
    return { startPosition, startRotation };
  }

  private stopPhysics() {
    const physicsComponent = this.parent.getComponent('PHYSICS');
    physicsComponent.dynamic = false;
  }

  private resumePhysics() {
    const physicsComponent = this.parent.getComponent('PHYSICS');
    physicsComponent.dynamic = true;
    physicsComponent.velocity = V3O.add(physicsComponent.velocity, V3O.scale(V3O.up(), 10));
  }
}

const addWind = (vector: V3): V3 => {
  const baseStrength = 0.001;
  const periodicAmplitude = 0.001;
  const speed = 0.001;
  const time = Date.now();
  const strength = Math.cos(time * speed) * periodicAmplitude + baseStrength;

  const xSpeed = 1 / 2000;
  const ySpeed = 1 / 3000;
  const zSpeed = 1 / 1000;

  const forceDirection = V3O.normalise([
    Math.sin(time * xSpeed) * 0.5,
    Math.cos(time * ySpeed),
    Math.sin(time * zSpeed),
  ]);

  const force = V3O.scale(forceDirection, strength);
  return V3O.add(force, vector);
};
