import React from 'react';
import 'styles/close-button.css';
import { useStore } from '../store/store';
import shallow from 'zustand/shallow';
import { Dispatch } from 'components/store/actions';
import { State } from 'components/store/state';
import closeButton from 'images/close_button.svg';

const selector = (state: State & { dispatch: Dispatch }) =>
  [state.paperDimensions, state.isCloseButtonVisible, state.dispatch] as const;

export const CloseButton = (): React.ReactElement | null => {
  const [{ right, top }, isCancelEnabled, dispatch] = useStore(selector, shallow);

  const width = 0.063 * window.innerHeight;

  return isCancelEnabled ? (
    <div
      style={{
        right: window.innerWidth - right * 1.02 + 'px',
        top: Math.max(top - width * 1.2, (top - width) / 2) + 'px',
        height: width + 'px',
        width: width + 'px',
      }}
      className="close-button"
      onClick={() => dispatch({ type: 'CANCEL_WRITING' })}
      onTouchEnd={(event) => {
        event.preventDefault();
        dispatch({ type: 'CANCEL_WRITING' });
      }}
      role="button"
      tabIndex={-1}
      onKeyPress={() => dispatch({ type: 'CANCEL_WRITING' })}
    >
      <img
        className="close-button-icon"
        height={top + 'px'}
        width={top + 'px'}
        src={closeButton}
        alt={''}
      ></img>
    </div>
  ) : null;
};
