import React, { useEffect, useState } from 'react';
import logo from 'images/logo.svg';
import 'styles/intro.css';
import { useStore } from '../store/store';
import shallow from 'zustand/shallow';
import { Dispatch } from 'components/store/actions';
import { State } from 'components/store/state';
import { CANVAS_DELAY_TIME, FADE_TIME, INTRO_HOLD_TIME } from 'config';
import { setTimeout } from 'timers';

const selector = (state: State & { dispatch: Dispatch }) => [state.dispatch] as const;

export const Intro = (): React.ReactElement | null => {
  const [dispatch] = useStore(selector, shallow);
  const [shouldFade, setShouldFade] = useState(false);
  const [isOnDOM, setIsOnDOM] = useState(true);
  const injectedStyle: React.CSSProperties = {
    opacity: !shouldFade ? 1 : 0,
    transition: `${FADE_TIME}s`,
  };

  useEffect(() => {
    const triggerCanvasShowing = setTimeout(() => {
      dispatch({ type: 'START_PLAYING' });
    }, CANVAS_DELAY_TIME);
    const fadeOut = setTimeout(() => {
      setShouldFade(true);
    }, INTRO_HOLD_TIME);
    const removeFromDOM = setTimeout(() => {
      setIsOnDOM(false);
    }, INTRO_HOLD_TIME + FADE_TIME * 1000);

    return () => {
      clearTimeout(fadeOut);
      clearTimeout(triggerCanvasShowing);
      clearTimeout(removeFromDOM);
    };
  }, [dispatch]);

  const isMobile = window.innerWidth <= 700;
  return isOnDOM ? (
    <div className="intro" style={injectedStyle} tabIndex={-1}>
      {isMobile ? (
        <div className="intro-text">
          Sinds jij
          <br />
          dood bent...
        </div>
      ) : (
        <div className="intro-text">Sinds jij dood bent...</div>
      )}

      <div className="footer">
        <img className="apple-logo" src={logo} alt={''} />
        <br />
        Het Klokhuis over dood en afscheid
      </div>
    </div>
  ) : null;
};
