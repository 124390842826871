import React, { useCallback, useRef } from 'react';
import { TEXT_SETTINGS } from 'config';
import { useStore } from 'components/store/store';
import 'styles/text-area.css';
import { Dispatch } from 'components/store/actions';
import shallow from 'zustand/shallow';
import { State } from 'components/store/state';
import { useMobileDetect } from 'utilities/use-mobile-detect-hook';

type TextAreaProps = {
  fontSize: number;
  lineHeight: number;
  width: number;
  top: number;
  bottom: number;
};

const selector = (state: State & { dispatch: Dispatch }) =>
  [state.isWritingEnabled, state.dispatch] as const;

export const TextArea = ({
  fontSize,
  lineHeight,
  width,
  bottom,
  top,
}: TextAreaProps): React.ReactElement => {
  const [isWritingEnabled, dispatch] = useStore(selector, shallow);
  const { isIos } = useMobileDetect();
  const ref = useRef<HTMLTextAreaElement>(null);

  const ENTER_KEY = 13;

  const onKeyUp = useCallback(
    (event: React.KeyboardEvent<HTMLTextAreaElement>): void => {
      if (event.keyCode !== ENTER_KEY) return;
      if ((event.target as HTMLTextAreaElement).value.length > TEXT_SETTINGS.minCharacters) {
        dispatch({
          type: 'ACCEPT_WRITING',
          payload: (event.target as HTMLTextAreaElement).value,
        });
      } else {
        ref.current?.blur();
      }
    },
    [dispatch],
  );

  const onInput = useCallback(
    (event: React.FormEvent<HTMLTextAreaElement>) => {
      const target = event.target as HTMLTextAreaElement;
      const message = target.value.slice(0, TEXT_SETTINGS.maxCharacters);
      target.value = message;
      dispatch({ type: 'SET_USER_TEXT', payload: target.value });
    },
    [dispatch],
  );

  const textAreaStyle: React.CSSProperties = {
    fontSize: fontSize + 'px',
    lineHeight: lineHeight + 'px',
    width: width + (isIos() ? 3 : 0) + 'px',
    top: top + 'px',
    height: `calc(100% - ${top + 'px'} - ${bottom + 'px'}`,
    left: isIos() ? '-3px' : '0',
  };

  return (
    <textarea
      className="text-area"
      style={textAreaStyle}
      ref={ref}
      // eslint-disable-next-line jsx-a11y/no-autofocus
      onInput={onInput}
      onKeyUp={onKeyUp}
      onFocus={() => {
        dispatch({
          type: 'FOCUS_WRITING',
        });
      }}
      onPaste={(event) => event.preventDefault()}
      onBlur={function (event) {
        if (event.target.value.length > TEXT_SETTINGS.minCharacters) {
          dispatch({
            type: 'ACCEPT_WRITING',
            payload: (event.target as HTMLTextAreaElement).value,
          });
        } else {
          dispatch({
            type: 'UNFOCUS_WRITING',
          });
        }
      }}
      key={0}
      maxLength={TEXT_SETTINGS.maxCharacters}
      disabled={!isWritingEnabled}
    />
  );
};
