import React, { useCallback, useEffect, useRef, useState } from 'react';
import 'styles/share.css';
import { useStore } from 'components/store/store';
import close from 'images/close_button_share_page.svg';
import { useMobileDetect } from 'utilities/use-mobile-detect-hook';

export const Share = ({ onClose }: { onClose: () => void }): React.ReactElement | null => {
  const supportsShareApi = navigator.share !== undefined;
  const [isNativeShareSuccessful, setIsNativeShareSuccessful] = useState<'not-known' | boolean>(
    supportsShareApi ? 'not-known' : false,
  );

  useEffect(() => {
    if (isNativeShareSuccessful !== 'not-known') return;

    const sharePromise = navigator.share({
      title: document.title,
      text:
        'Schrijf hier anoniem wat jij voelt sinds iemand die jij kent dood is. | Het Klokhuis over dood en afscheid',
      url: window.location.href,
    });

    const abortablePromise = createAbortablePromise(sharePromise);
    abortablePromise.promise
      .then(() => setIsNativeShareSuccessful(true))
      .catch((error) => {
        if (error instanceof DOMException) {
          setIsNativeShareSuccessful(true);
        } else {
          console.log(error);
          setIsNativeShareSuccessful(false);
        }
      });

    return () => abortablePromise.abort();
  }, [isNativeShareSuccessful, onClose]);

  if (isNativeShareSuccessful === false) {
    return <ShareScreen onClose={onClose} />;
  } else if (isNativeShareSuccessful === 'not-known') {
    return null;
  } else {
    onClose();
    return null;
  }
};

const ShareScreen = ({ onClose }: { onClose: () => void }): React.ReactElement | null => {
  const lights = useStore((state) => state.lights);

  const canvasRef = useRef<HTMLCanvasElement>(null);

  const [isShareRendered, setShareRendered] = useState(false);
  const { isDesktop } = useMobileDetect();
  const buttonText = isDesktop() ? 'Klik om het project te delen' : 'Tap om het project te delen';
  const [text, setText] = useState(buttonText);
  useEffect(() => {
    setShareRendered(true);
  }, []);

  const timeout = useRef<NodeJS.Timeout | undefined>(undefined);
  useEffect(() => {
    if (text === buttonText) return;

    copyTextToClipboard(window.location.href);
    if (timeout.current !== undefined) {
      clearTimeout(timeout.current);
    }

    timeout.current = setTimeout(() => {
      setText(buttonText);
    }, 1000);

    return () => {
      timeout.current !== undefined && clearTimeout(timeout.current);
    };
  }, [buttonText, text]);

  useAnimationFrame(() => {
    const canvas = canvasRef.current;
    if (canvas === null) return;

    const context = canvas.getContext('2d');
    if (!context) return;
    const gradient = context.createLinearGradient(0, 0, 0, canvas.height);
    gradient.addColorStop(0, '#' + lights.directional.getHexString());
    gradient.addColorStop(1, '#' + lights.ambient.getHexString());

    context.fillStyle = gradient;
    context.fillRect(0, 0, canvas.width, canvas.height);
  });

  return (
    <div className={`share-container ${isShareRendered ? 'share-onscreen' : 'share-offscreen'}`}>
      <canvas
        style={{ width: '100%', height: '100%', position: 'absolute' }}
        width={window.innerWidth}
        height={window.innerHeight}
        ref={canvasRef}
      ></canvas>
      <div className="share" tabIndex={-1}>
        <div className="share-text">
          <div
            tabIndex={-1}
            role="button"
            onClick={() => setText('URL gekopieerd!')}
            onTouchEnd={() => setText('URL gekopieerd!')}
            onKeyPress={() => setText('URL gekopieerd!')}
          >
            {text}
          </div>
        </div>
      </div>
      <div
        className="share-close-button"
        onClick={() => {
          onClose();
        }}
        onTouchEnd={(event) => {
          event.preventDefault();
          onClose();
        }}
        role="button"
        tabIndex={-1}
        onKeyPress={() => {
          onClose();
        }}
      >
        <img className="share-close-button-icon" src={close} alt={''}></img>
      </div>
    </div>
  );
};

const useAnimationFrame = (callback: (deltaTime: number) => void) => {
  const requestRef = React.useRef<number>();
  const previousTimeRef = React.useRef<number>();

  const animate = useCallback(
    (time: number) => {
      if (previousTimeRef.current !== undefined) {
        const deltaTime = time - previousTimeRef.current;
        callback(deltaTime);
      }
      previousTimeRef.current = time;
      requestRef.current = requestAnimationFrame(animate);
    },
    [callback],
  );

  React.useEffect(() => {
    requestRef.current = requestAnimationFrame(animate);
    return () => {
      requestRef.current && cancelAnimationFrame(requestRef.current);
    };
  }, [animate]);
};

function fallbackCopyTextToClipboard(text: string) {
  const textArea = document.createElement('textarea');
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    document.execCommand('copy');
  } catch (err) {
    console.error('Could not copy share text: ', err);
  }

  document.body.removeChild(textArea);
}

function copyTextToClipboard(text: string) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text).then(undefined, function (err) {
    console.error('Could not copy share text: ', err);
  });
}

function createAbortablePromise<T>(
  promise: Promise<T>,
): { promise: Promise<T>; abort: () => void } {
  const abortController = new AbortController();
  const signal = abortController.signal;
  const result = new Promise<T>(async (resolve, reject) => {
    signal.addEventListener('abort', () => reject('Promise was aborted'));
    promise.then(resolve).catch(reject);
  });

  const abort = () => abortController.abort();

  return { promise: result, abort };
}
