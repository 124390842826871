import { V3 } from '../../vectors/v3';

export type ForceField = (position: V3, velocity: V3) => V3;

export function applyForceFields(forceFields: ForceField[], position: V3, velocity: V3): V3[] {
  const result = new Array<V3>(forceFields.length);
  for (let index = 0; index < forceFields.length; index++) {
    const field = forceFields[index];
    result[index] = field(position, velocity);
  }

  return result;
}
