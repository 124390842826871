import { V3 } from '../../vectors/v3';

export type AccelerationField = () => V3;

export function applyAccelerationFields(forceFields: AccelerationField[]): V3[] {
  const result = new Array<V3>(forceFields.length);
  for (let index = 0; index < forceFields.length; index++) {
    const field = forceFields[index];
    result[index] = field();
  }
  return result;
}
