import React from 'react';
import './logos.css';

export const Logos = (): React.ReactElement => {
  return (
    <>
      <div className="Logos">
        <a href="https://www.hetklokhuis.nl" title="Het Klokhuis" target="_blank" rel="noreferrer">
          <img src="logo-hetklokhuis.png" alt="Logo Het Klokhuis" />
        </a>
        <a
          href="http://www.zapp.nl/?ns_campaign=meerzapp&ns_mchannel=omroep&ns_source=ntr&ns_linkname=button"
          title="NPO Zapp"
          target="_blank"
          rel="noreferrer"
        >
          <img src="logo-npozapp.png" alt="Logo NPO Zapp" />
        </a>
      </div>
    </>
  );
};
