import { WrittenPaperComponent } from './paper/written-paper-component';
/* eslint-disable @typescript-eslint/no-explicit-any */
import { AnimationComponent } from './animations/animation-component';
import { Component, ComponentTypes } from './component';
import { BufferedDisplayComponent } from './display/buffered-display-component';
import { MeshComponent } from './mesh/mesh-component';
import { PhysicsComponent } from './physics/physics-component';
import { TransformComponent } from './transform/transform-component';
import { ClothComponent } from './cloth/cloth-component';
import { ServerPaperComponent } from './paper/server-paper-component';

export interface ComponentTypeMap {
  BUFFERED_DISPLAY: BufferedDisplayComponent;
  PHYSICS: PhysicsComponent;
  ANIMATION: AnimationComponent;
  TRANSFORM: TransformComponent;
  MESH: MeshComponent;
  SERVER_PAPER: ServerPaperComponent;
  WRITTEN_PAPER: WrittenPaperComponent;
  CLOTH: ClothComponent;
}

export class Entity {
  components: Map<ComponentTypes, Component> = new Map();

  setComponent<T extends new (...args: any[]) => Component>(
    constructor: T,
    ...parameters: ConstructorParameters<T>
  ): void {
    const component = new (constructor as new (...args: ConstructorParameters<T>) => Component)(
      ...parameters,
    );
    this.components.set(component.type, component);
  }

  trySetComponent<T extends new (...args: any[]) => Component>(
    constructor: T,
    ...parameters: ConstructorParameters<T>
  ): void {
    const component = new (constructor as new (...args: ConstructorParameters<T>) => Component)(
      ...parameters,
    );
    if (!this.hasComponent(component.type)) this.components.set(component.type, component);
  }

  hasComponent(component: ComponentTypes): boolean {
    return this.components.has(component);
  }

  removeComponent(component: ComponentTypes): void {
    this.components.delete(component);
  }

  tryGetComponent<T extends keyof ComponentTypeMap>(component: T): ComponentTypeMap[T] | undefined {
    const maybeComponent = this.components.get(component as ComponentTypes);
    return maybeComponent as ComponentTypeMap[T];
  }

  getComponent<T extends keyof ComponentTypeMap>(component: T): ComponentTypeMap[T] {
    const maybeComponent = this.components.get(component as ComponentTypes);
    if (!maybeComponent) this.throwMissingComponentError(component as ComponentTypes);
    return maybeComponent as ComponentTypeMap[T];
  }

  throwMissingComponentError(component: ComponentTypes): void {
    throw new Error(`Parent of ${this.constructor.name} does not have component ${component}`);
  }
}
