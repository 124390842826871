import React from 'react';
import { TEXT_SETTINGS } from 'config';
import 'styles/letter-counter.css';
import { useStore } from '../store/store';
import { State } from 'components/store/state';

type LetterCounterProps = {
  bottom: number;
  width: number;
  fontSize: number;
};

const selector = (state: State) => state.userText.length;

export const LetterCounter = ({
  bottom,
  width,
  fontSize,
}: LetterCounterProps): React.ReactElement | null => {
  const userTextLength = useStore(selector);

  const letterCounterStyle: React.CSSProperties = {
    fontSize: fontSize + 'px',
    bottom: bottom + 'px',
    width: width + 'px',
  };

  const remainingCharacters = TEXT_SETTINGS.maxCharacters - userTextLength;
  return (
    <div style={letterCounterStyle} className="letter-counter">
      {remainingCharacters}
    </div>
  );
};
