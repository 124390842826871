import React from 'react';

import 'styles/background.css';
import { ImageLoader } from 'three';
import { useControl } from 'react-three-gui';

export const BackgroundGUI = (): React.ReactElement => {
  const loader = new ImageLoader();

  const background = useControl('Background image', {
    type: 'file',
    loader: loader,
  }) as HTMLImageElement;

  return (
    <>
      <div
        style={{
          backgroundImage: `url(${background.src})`,
          opacity: '1',
        }}
        className="Background"
      ></div>
      <div className="background-gradient" />
    </>
  );
};
