import React, { CSSProperties, useEffect, useState } from 'react';
import { TEXT_SETTINGS, FADE_TIME } from 'config';
import 'styles/text-input.css';
import { useStore } from '../store/store';
import { TextArea } from './text-area';
import { LetterCounter } from './letter-counter';
import shallow from 'zustand/shallow';
import { State } from 'components/store/state';

const selector = (state: State) =>
  [state.paperDimensions, state.isDOMWritingVisible, state.isCanvasWritingVisible] as const;

export const TextInput = (): React.ReactElement | null => {
  const [{ width, top, left, bottom }, isDOMWritingVisible, isCanvasWritingVisible] = useStore(
    selector,
    shallow,
  );
  const [scrollOffset, setScrollOffset] = useState<number>(window.scrollY);

  const {
    lineHeight,
    paddingLeft,
    paddingRight,
    paddingTop,
    paddingBottom,
    fontSize,
  } = scaleTextSettings(width);

  const adjustIOSScrollPosition = () => {
    const adjustScroll = () => setScrollOffset(window.scrollY);
    window.addEventListener('scroll', adjustScroll);
    return () => window.removeEventListener('scroll', adjustScroll);
  };
  useEffect(adjustIOSScrollPosition, []);

  const offset = 0.8 * lineHeight;
  const fadeStyle = getFadeStyle(!isCanvasWritingVisible);

  const textInputLeft = left + paddingLeft;
  const textInputWidth = width - paddingLeft - paddingRight;
  const textInputTop = top + getRelativeScrollOffset(top, scrollOffset) - offset + paddingTop;
  const textInputBottom = bottom + getRelativeScrollOffset(bottom - offset, scrollOffset);
  const textInputHeight = textInputBottom - textInputTop - paddingBottom * 2;

  const textInputStyle = addPxToStyle({
    left: textInputLeft,
    width: textInputWidth,
    top: textInputTop,
    height: textInputHeight,
    fontSize,
    lineHeight,
  });

  const textAreaTop = TEXT_SETTINGS.userTextLineOffset * lineHeight;
  const letterCounterFontSize = fontSize * 0.53;

  return isDOMWritingVisible ? (
    <div className="text-input" style={{ ...textInputStyle, ...fadeStyle }}>
      <LetterCounter bottom={-fontSize} width={textInputWidth} fontSize={letterCounterFontSize} />
      <TextArea
        fontSize={fontSize}
        lineHeight={lineHeight}
        width={textInputWidth}
        top={textAreaTop}
        bottom={0}
      />
    </div>
  ) : null;
};

function getRelativeScrollOffset(absoluteDistance: number, scrollOffset: number) {
  return (absoluteDistance / window.innerHeight) * scrollOffset;
}

function addPxToStyle(style: Partial<Record<keyof CSSProperties, number>>): CSSProperties {
  return Object.entries(style).reduce((acc, [key, value]) => {
    return { ...acc, [key]: value + 'px' };
  }, {} as CSSProperties);
}

function scaleTextSettings(width: number) {
  const fontSize = TEXT_SETTINGS.fontSize * width;
  const lineHeight = TEXT_SETTINGS.lineHeight * width;
  const paddingLeft = TEXT_SETTINGS.paddingLeft * width;
  const paddingRight = TEXT_SETTINGS.paddingRight * width;
  const paddingTop = TEXT_SETTINGS.paddingTop * width;
  const paddingBottom = TEXT_SETTINGS.paddingBottom * width;
  return { lineHeight, paddingLeft, paddingRight, paddingTop, paddingBottom, fontSize };
}

function getFadeStyle(isVisible: boolean): React.CSSProperties {
  return {
    opacity: isVisible ? 1 : 0,
    transition: `opacity ${FADE_TIME}s`,
    touchAction: isVisible ? 'auto' : 'none',
    pointerEvents: isVisible ? 'auto' : 'none',
  };
}
