import { useStore } from 'components/store/store';
import { Howl } from 'howler';
import { useEffect } from 'react';
import backgroundMP3 from 'sound/background.mp3';
import backgroundWav from 'sound/background.wav';
import chooseInMP3 from 'sound/choose-in.mp3';
import chooseInWav from 'sound/choose-in.wav';
import chooseOutMP3 from 'sound/choose-out.mp3';
import chooseOutWav from 'sound/choose-out.wav';
import writeBeginMP3 from 'sound/write-begin.mp3';
import writeBeginWav from 'sound/write-begin.wav';

export const Sound = (): null => {
  const chooseInCount = useStore((state) => state.chooseInCount);
  const chooseOutCount = useStore((state) => state.chooseOutCount);
  const writeBeginCount = useStore((state) => state.writeBeginCount);

  useEffect(() => {
    const playBackgroundMusic = () => {
      const { background } = loadSounds();
      if (!background.playing()) {
        background.play();
        background.fade(0, 1, 500);
      }
      background.playing() && document.removeEventListener('mouseup', playBackgroundMusic);
    };

    document.addEventListener('mouseup', playBackgroundMusic);
    document.addEventListener('touchend', playBackgroundMusic);

    return () => {
      document.removeEventListener('mouseup', playBackgroundMusic);
      document.removeEventListener('touchend', playBackgroundMusic);
    };
  }, []);

  useEffect(() => {
    if (writeBeginCount === 0) return;
    const { writeBegin } = loadSounds();
    writeBegin.play();
  }, [writeBeginCount]);

  useEffect(() => {
    if (chooseInCount === 0) return;
    const { chooseIn } = loadSounds();
    chooseIn.play();
  }, [chooseInCount]);

  useEffect(() => {
    if (chooseOutCount === 0) return;
    const { chooseOut } = loadSounds();
    chooseOut.play();
  }, [chooseOutCount]);

  return null;
};

let sounds: { background: Howl; chooseIn: Howl; chooseOut: Howl; writeBegin: Howl } | undefined;
function loadSounds() {
  if (sounds !== undefined) return sounds;

  const background = new Howl({
    src: [backgroundMP3, backgroundWav],
    preload: true,
    loop: true,
    volume: 0.0,
  });

  const chooseIn = new Howl({
    src: [chooseInMP3, chooseInWav],
    preload: true,
    volume: 1,
  });

  const chooseOut = new Howl({
    preload: true,
    src: [chooseOutMP3, chooseOutWav],
    volume: 1.0,
  });

  const writeBegin = new Howl({
    preload: true,
    src: [writeBeginMP3, writeBeginWav],
    volume: 1.0,
  });

  sounds = { chooseIn, background, chooseOut, writeBegin };
  return sounds;
}
