import { V2O } from '../../vectors/v2';
import { V3, V3O } from '../../vectors/v3';
import { ForceField } from './force-field';

export const createTorusForceField = ({
  center,
  dimensions,
  torusRadius,
  maxForces,
}: {
  center: V3;
  dimensions: V3;
  torusRadius: number;
  maxForces: V3;
}): ForceField => {
  function torusForceField(position: V3) {
    const distance = V3O.subtract(position, center);
    const unit = V3O.normalise(distance);

    const ringXZDimensions = V3O.extractXZ(dimensions);
    const ringXZRadius = V2O.euclideanLength(ringXZDimensions);

    const distanceToClosestPointOnRing = getDistanceToClosestPointOnRing(
      unit,
      ringXZRadius,
      center,
      position,
    );

    const forceMagnitude = V3O.multiply(
      V3O.scale(distanceToClosestPointOnRing, -1 / torusRadius),
      maxForces,
    );

    return forceMagnitude;
  }

  return torusForceField;
};

function getDistanceToClosestPointOnRing(unit: V3, ringXZRadius: number, center: V3, position: V3) {
  const closestPointOnRing = V3O.add(V3O.scale(unit, ringXZRadius), center);
  const distanceToPointOnRing = V3O.subtract(position, closestPointOnRing);
  return distanceToPointOnRing;
}
