import { V3O } from '../../vectors/v3';
import { ForceField } from './force-field';

export const createWindForceField = ({
  speed,
  periodicAmplitude,
  baseStrength,
  updateRate,
}: {
  speed: number;
  periodicAmplitude: number;
  baseStrength: number;
  updateRate: number;
}): ForceField => {
  const xSpeed = 1 / 2000;
  const ySpeed = 1 / 3000;
  const zSpeed = 1 / 1000;

  let lastTime = Date.now();
  let lastForce = V3O.zero();

  function windForceField() {
    const time = Date.now();
    const delta = time - lastTime;

    if (delta < updateRate) {
      return lastForce;
    }

    const forceDirection = V3O.normalise([
      Math.sin(time * xSpeed) * 0.5,
      Math.cos(time * ySpeed),
      Math.sin(time * zSpeed),
    ]);

    const strength = Math.cos(time * speed) * periodicAmplitude + baseStrength;
    const force = V3O.scale(forceDirection, strength);

    lastTime = time;
    lastForce = force;
    return force;
  }
  return windForceField;
};
