import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './app';
import { removeContextMenu } from './remove-context-menu';
import './styles/index.css';
import 'inobounce';

removeContextMenu();

ReactDOM.render(<App />, document.getElementById('root'));
