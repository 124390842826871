export const easing = {
  linear: (t: number): number => t,
  easeInQuad: (t: number): number => t * t,
  easeInCubic: (t: number): number => t * t * t,
  easeInQuint: (t: number): number => t * t * t * t,

  easeOutQuad: (t: number): number => 1 - easing.easeInQuad(1 - t),
  easeOutCubic: (t: number): number => 1 - easing.easeInCubic(1 - t),
  easeOutQuint: (t: number): number => 1 - easing.easeInQuint(1 - t),
  easeOutCirc: (x: number): number => Math.sqrt(1 - Math.pow(x - 1, 2)),

  easeInOutQuad: (t: number): number =>
    t < 0.5 ? easing.easeInQuad(t * 2) / 2 : 1 - easing.easeInQuad((1 - t) * 2) / 2,
  easeInOutCubic: (t: number): number =>
    t < 0.5 ? easing.easeInCubic(t * 2) / 2 : 1 - easing.easeInCubic((1 - t) * 2) / 2,
  easeInOutQuint: (t: number): number =>
    t < 0.5 ? easing.easeInQuint(t * 2) / 2 : 1 - easing.easeInQuint((1 - t) * 2) / 2,

  easeOutElastic: (t: number): number =>
    Math.pow(2, -10 * t) * Math.sin(((t - 0.3 / 4) * (2 * Math.PI)) / 0.3) + 1,
};

export type EasingFunctions = keyof typeof easing;
