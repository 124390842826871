import { Component } from '../component';
import { Entity } from '../entity';
import { EasingFunctions } from './easing';
import { easing } from './easing';
import { AnimationComponentData } from './animation-component';
import { IAnimationComponent } from './i-animation-component';

type TimedAnimationComponentData = {
  durationMillis: number;
} & Omit<AnimationComponentData, 'getProgress'>;

export class TimedAnimationComponent extends Component implements IAnimationComponent {
  private static readonly START_PROGRESS = 0;
  private static readonly END_PROGRESS = 1;

  curve: EasingFunctions;
  progress: number;
  complete = false;

  getProgress: (entity: Entity, animation: IAnimationComponent, progress: number) => number;
  onProgress?: (entity: Entity, animation: IAnimationComponent, progress: number) => void;
  onComplete?: (entity: Entity, animation: IAnimationComponent, progress: number) => void;
  onStop?: (entity: Entity) => void;
  startTime: number;
  durationMillis: number;

  constructor(
    parent: Entity,
    {
      durationMillis,
      curve,
      progress = TimedAnimationComponent.START_PROGRESS,
      onProgress,
      onComplete,
      onStop,
    }: TimedAnimationComponentData,
  ) {
    super({ type: 'ANIMATION', parent, dependencies: ['TRANSFORM'] });
    this.curve = curve;
    this.progress = progress;
    this.durationMillis = durationMillis;

    this.getProgress = () => {
      if (this.complete) return 1.0;
      const currentTime = Date.now();
      const progress = (currentTime - this.startTime) / this.durationMillis;
      return progress;
    };

    this.onProgress = onProgress;
    this.onComplete = onComplete;
    this.onStop = onStop;

    this.startTime = Date.now() - progress * durationMillis;
  }

  update(): void {
    this.validateDependencies();

    const progress = this.getProgress(this.parent, this, this.progress);
    const easedProgress = easing[this.curve](progress);
    this.onProgress?.(this.parent, this, easedProgress);

    if (progress >= TimedAnimationComponent.END_PROGRESS && !this.complete) {
      this.onComplete?.(this.parent, this, easedProgress);
      this.complete = true;
    }
  }

  stop(): void {
    this.parent.removeComponent('ANIMATION');
    this.onStop?.(this.parent);
  }
}
